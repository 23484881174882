import type { Company } from "@/models";
import type { KeyValue, PickPartial, OmitPartial } from "@/types";
import { CompaniesService, RequestCompanyCreateParam, RequestCompanyUpdateParam } from "@/openapi";

export interface ICompanyRepository {
  getAll: () => Promise<PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">[]>;
  template: () => OmitPartial<Company, "id">;
  get: (id: string) => Promise<Company>;
  getGeneratedCompanyCode: () => Promise<string>;
  checkCompanyCode: (companyCode: string) => Promise<boolean>;
  getEepsilonRegistrationDate: (id: string) => Promise<string | null>;
  register: (data: Partial<Company>) => Promise<string>;
  update: (id: string, data: Company) => Promise<void>;
}

export class CompanyRepository implements ICompanyRepository {
  public async getAll(): Promise<PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">[]> {
    return (await CompaniesService.getCompaniesService()) as KeyValue<string>[];
  }
  public template(): OmitPartial<Company, "id"> {
    return {
      companyCode: "",
      riyoKaishiDate: "",
      riyoShuryoDate: null,
      jigyoTypeId: null,
      name: "",
      nameShort: null,
      postalCode: null,
      address1: null,
      address2: null,
      tel: null,
      fax: null,
      tantosha: null,
      sekininsha: null,
      email: null,
      hasSeikyuSaki: false,
      seikyuSakiAtesaki: null,
      seikyuSakiTantosha: null,
      seikyuSakiPostalCode: null,
      seikyuSakiAddress1: null,
      seikyuSakiAddress2: null,
      seikyuSakiTel: null,
      isUseKyuyoFeature: false,
    };
  }
  public async get(id: string): Promise<Company> {
    const company = await CompaniesService.getCompaniesService1({ id });
    return company as Company;
  }
  public async getGeneratedCompanyCode(): Promise<string> {
    return await CompaniesService.getCompaniesService2();
  }
  public async checkCompanyCode(companyCode: string): Promise<boolean> {
    return await CompaniesService.getCompaniesService3({ companyCode });
  }
  public async getEepsilonRegistrationDate(id: string): Promise<string | null> {
    return (await CompaniesService.getCompaniesService4({ id })).epsilonRegistrationDate ?? null;
  }
  public async register(data: Partial<Company>): Promise<string> {
    return (await CompaniesService.postCompaniesService({
      requestBody: data as RequestCompanyCreateParam,
    })) as string;
  }
  public async update(id: string, data: Company): Promise<void> {
    await CompaniesService.putCompaniesService({
      id,
      requestBody: data as RequestCompanyUpdateParam,
    });
  }
}
