import Vue from "vue";
import VueCompositionApi, { provide } from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { repositories, RepositoriesKey, IRepositories } from "./repositories/RepositoryFactory";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { SiteSetting } from "@/env";
import { OpenAPI } from "@/openapi";

// Application Insights設定
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: SiteSetting.applicationInsightsKey,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

// OpenAPI設定
OpenAPI.BASE = SiteSetting.baseApiUrl;

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;

new Vue({
  router,
  store: store.original,
  vuetify,
  render: (h) => h(App),
  setup() {
    provide<IRepositories>(RepositoriesKey, repositories);
    return {};
  },
}).$mount("#app");
