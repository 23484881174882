






























































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import AdminTemplate from "@/components/templates/AdminTemplate.vue";
import DataTable from "@/components/molecules/DataTable.vue";
import FileInput from "@/components/atoms/FileInput.vue";
import { SaiteiChingin } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import { DataTableHeader } from "vuetify/types";
import globalStore from "@/store";
import { IRepositories } from "@/repositories/RepositoryFactory";
import dayjs from "dayjs";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    saiteiChingins: SaiteiChingin[];
    formData: {
      uploadData: File | null;
      uploadFileName: string;
    };
  }>({
    saiteiChingins: [],
    formData: {
      uploadData: null,
      uploadFileName: "",
    },
  });

  const init = async () => {
    await get();
  };
  const get = async () => {
    state.saiteiChingins = await repositories.saiteiChinginRepository.get();
  };
  const upload = async () => {
    if (state.formData.uploadData) {
      await repositories.saiteiChinginRepository.register(state.formData.uploadData);
      state.formData.uploadData = null;
      state.formData.uploadFileName = "";
    }
  };
  return { state, init, get, upload };
};

// セットアップ
export default defineComponent({
  components: {
    AdminTemplate,
    DataTable,
    FileInput,
  },
  setup(props, context) {
    const { info, repositories, initApp, handleError } = common();
    const { confirm } = globalConfirm();
    const { state, init, get, upload } = store(repositories);
    const form = ref<VForm>();
    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await initApp();
        await init();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
    const onUpload = async () => {
      if (form.value?.validate()) {
        if (
          !(await confirm("最低賃金データを取り込みますか?", {
            title: "最低賃金データ取込",
            okLabel: "最低賃金データを取り込む",
          }))
        ) {
          return false;
        }
        try {
          info.dispSmallProgress();
          await upload();
          await get();
          form.value?.resetValidation();
          info.dispSuccess("取込に成功しました。");
        } catch (e) {
          handleError(e, context.root.$router, "取込に失敗しました。");
        }
        info.closeProgress();
      }
    };
    const tableHeaders: DataTableHeader[] = [
      { text: "都道府県", value: "todofukenName" },
      { text: "最低賃金時間額	", value: "jikanGaku" },
      { text: "発効年月日", value: "startDate" },
    ];
    const todofukenTypes = computed(() => {
      return globalStore.getters.codeMasters.todofukenTypes;
    });

    const tableData = computed(() => {
      var todofukenTypes = globalStore.getters.codeMasters.todofukenTypes;
      return state.saiteiChingins.map((x) => ({
        todofukenName: todofukenTypes.find((y) => y.id == x.todofukenId)?.name,
        jikanGaku: x.jikanGaku,
        startDate: dayjs(x.startDate).format("YYYY/MM/DD"),
      }));
    });

    return {
      state,
      form,
      rules,
      onUpload,
      tableHeaders,
      tableData,
      todofukenTypes,
      EditMode,
    };
  },
});
