import { KyuyoTaikeisService } from "@/openapi";
import { KyuyoTaikei } from "@/models";

export interface IKyuyoTaikeiRepository {
  getAll: (companyId: string) => Promise<KyuyoTaikei[]>;
  import(companyId: string, data: File, prefix?: string | null, suffix?: string | null): Promise<void>;
  export(companyId: string, kyuyoTaikeiIds: string[]): Promise<Blob | null>;
}

export class KyuyoTaikeiRepository implements IKyuyoTaikeiRepository {
  public async getAll(companyId: string): Promise<KyuyoTaikei[]> {
    return (await KyuyoTaikeisService.getKyuyoTaikeisService({ companyId: companyId })) as KyuyoTaikei[];
  }
  async import(companyId: string, data: File, prefix?: string, suffix?: string): Promise<void> {
    await KyuyoTaikeisService.postKyuyoTaikeisService({
      companyId: companyId,
      formData: { jsonFile: data },
      prefix: prefix,
      suffix: suffix,
    });
  }

  async export(companyId: string, kyuyoTaikeiIds: string[]): Promise<Blob | null> {
    return await KyuyoTaikeisService.putKyuyoTaikeisService({
      companyId: companyId,
      requestBody: kyuyoTaikeiIds,
    });
  }
}
