/* eslint-disable @typescript-eslint/no-var-requires */

import { OpenAPI } from "@/openapi";
export const API_BASE = OpenAPI.BASE;

export const SiteSetting = require(`@/data/site.${process.env.NODE_ENV}.ts`).default as SiteSettings;
export const BLOB_URL = `${SiteSetting.baseApiUrl}/api/Blobs`;

export const API = OpenAPI;
export class SiteSettings {
  name!: string;
  baseUrl!: string;
  deployFolder!: string;
  baseApiUrl!: string;
  applicationInsightsKey!: string;
  lightAppUrl!: string;
}
