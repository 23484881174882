









































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { InputValidationRule } from "vuetify";
import { KeyValue } from "@/types";
import { EditMode } from "@/composable/utils/const";

export default defineComponent({
  props: {
    value: {
      type: [String, Number, Array] as PropType<string | number | Array<string | number>>,
      default: null,
    },
    originalValue: {
      type: [String, Number, Array] as PropType<string | number | Array<string | number>>,
    },
    editMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
    items: {
      type: Array as PropType<KeyValue<string | number>[]>,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<InputValidationRule[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const innerValue = computed({
      get: () => props.value,
      set: (value: string | number | Array<string | number>) => {
        context.emit("input", value);
        context.emit("change");
      },
    });
    const bgColor = computed(() => {
      if (props.editMode === EditMode.Edit && props.originalValue !== undefined) {
        if (JSON.stringify(props.value) !== JSON.stringify(props.originalValue)) {
          return "blue lighten-5";
        }
      }
      return "";
    });
    const originalText = computed(() => {
      if (props.multiple && Array.isArray(props.originalValue)) {
        return props.originalValue.map((x) => props.items.find((y) => y.id === x)?.name ?? "").join(", ");
      } else if (props.originalValue) {
        return props.items.find((x) => x.id === props.originalValue)?.name ?? "";
      }
      return props.items.find((x) => x.id === props.value)?.name ?? "";
    });
    const readOnly = computed(() => props.editMode === EditMode.Read);
    return { innerValue, bgColor, originalText, readOnly };
  },
});
