




















import { defineComponent, onMounted, onUnmounted, ref } from "@vue/composition-api";
import AdminTemplate from "@/components/templates/AdminTemplate.vue";

export default defineComponent({
  components: {
    AdminTemplate,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const listField = ref<HTMLElement>();
    onMounted(async () => {
      onResize();
      window.addEventListener("resize", onResize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });
    const onResize = () => {
      if (listField.value) {
        listField.value.style.height = document.documentElement.clientHeight - 88 + "px";
      }
    };
    return { listField };
  },
});
