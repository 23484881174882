/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum KyuyoItemType {
    _10 = 10,
    _20 = 20,
    _30 = 30,
    _40 = 40,
    _50 = 50,
    _70 = 70,
    _80 = 80,
    _90 = 90,
}