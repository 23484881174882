
























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    validationMessage: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const save = () => {
      context.emit("save");
    };
    const cancel = () => {
      context.emit("cancel");
    };
    return { save, cancel };
  },
});
