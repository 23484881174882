







































































































import { defineComponent, reactive, PropType, computed, watch } from "@vue/composition-api";
import { Company } from "@/models";
import { CompanyKeiyakuStatus } from "@/composable/utils/const";
import { PickPartial } from "@/types";

export default defineComponent({
  props: {
    value: {
      type: String,
      default: null,
    },
    companies: {
      type: Array as PropType<Readonly<PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">>[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
  },
  setup(props, context) {
    const state = reactive({
      searchParam: {
        word: "",
        companyKeiyakuStatus: [
          CompanyKeiyakuStatus.通常版利用中,
          CompanyKeiyakuStatus.有料ライト版利用中,
          CompanyKeiyakuStatus.無料ライト版利用中,
        ] as number[],
      },
      page: 1,
      innerValue: props.value,
    });

    const filteredCompanies = computed(() => {
      const companies = props.companies.filter((x) => {
        if (state.searchParam.word != null && state.searchParam.word !== "") {
          if (
            !(
              x.companyCode?.match(state.searchParam.word) ||
              x.name?.match(state.searchParam.word) ||
              x.nameShort?.match(state.searchParam.word)
            )
          )
            return false;
        }
        if (
          x.companyKeiyakuStatus != null &&
          !state.searchParam.companyKeiyakuStatus.includes(x.companyKeiyakuStatus)
        ) {
          return false;
        }
        return true;
      });
      return companies;
    });

    const paginatedCompanies = computed(() => {
      const companies = filteredCompanies.value.filter(
        (x, index) =>
          index >= (state.page - 1) * props.itemsPerPage &&
          index < (state.page - 1) * props.itemsPerPage + props.itemsPerPage
      );
      if (companies.find((x) => x.id === props.value)) {
        state.innerValue = props.value;
      }
      return companies;
    });

    // props.value初回設定時に選択状態を反映
    watch(
      () => props.value,
      () => {
        state.innerValue = props.value;
      }
    );

    const pageCount = computed(() => {
      return Math.ceil(filteredCompanies.value.length / props.itemsPerPage);
    });

    const onChange = () => {
      if (state.innerValue != null) {
        context.emit("input", state.innerValue);
        context.emit("change");
      }
    };
    const onChangeFilter = () => {
      if (state.page > pageCount.value) {
        state.page = 1;
      }
    };
    const onPaginate = () => {
      state.innerValue = props.value;
    };
    return {
      state,
      paginatedCompanies,
      filteredCompanies,
      pageCount,
      onChange,
      onPaginate,
      onChangeFilter,
      CompanyKeiyakuStatus,
    };
  },
});
