/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { inject } from "@vue/composition-api";
import { RepositoriesKey, IRepositories } from "@/repositories/RepositoryFactory";
import store from "@/store";
import VueRouter from "vue-router";
import { ApiError } from "@/openapi";

export const common = () => {
  // リポジトリinject
  const repositories = inject<IRepositories>(RepositoriesKey);
  if (!repositories) {
    throw new Error("リポジトリの初期化に失敗しました。");
  }

  // 共通エラー処理
  const handleError = (
    e: Error,
    router: VueRouter,
    message: string,
    httpStatusMessages: { status: number; message: string }[] = []
  ) => {
    if (e instanceof ApiError) {
      message = httpStatusMessages.find((x) => x.status === e.status)?.message ?? message;
      if (e.status === 401) {
        message = "認証に失敗しました。再ログインしてください。";
        repositories.authRepository?.logout();
        store.dispatch.auth.logout();
        router.push({ name: "login" });
      } else if (e.status === 406) {
        // 406の場合、BEのエラーメッセージを表示
        message = e.body ?? message;
      }
    }
    info.dispError(message);
  };
  // 初期化処理
  const initApp = async () => {
    // リロード時tokenを設定
    await repositories.authRepository.restore(store.getters.auth.token);
    // codeMasters取得
    if (!store.getters.codeMasters.isInited) {
      const res = await repositories.codeMastersRepository.get();
      store.dispatch.codeMasters.setCodeMasters(res);
    }
  };

  const info = {
    dispError: (message: string) => {
      store.dispatch.app.dispError(message);
    },
    dispSuccess: (message: string) => {
      store.dispatch.app.dispSuccess(message);
    },
    closeProgress: () => {
      store.dispatch.app.closeProgress();
    },
    dispLargeProgress: (message: string | null = null) => {
      store.dispatch.app.dispLargeProgress(message);
    },
    dispSmallProgress: (message: string | null = null) => {
      store.dispatch.app.dispSmallProgress(message);
    },
  };

  const downloadFile = (blob: Blob, fileName: string, mimeType: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.dataset.downloadurl = [mimeType, downloadLink.download, downloadLink.href].join(":");
    // イベント実行
    downloadLink.click();
  };

  return { repositories, initApp, info, handleError, downloadFile };
};
