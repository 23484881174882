import { KyuyoTaikei } from "@/models";
import { IKyuyoTaikeiRepository } from "@/repositories";

export class MockKyuyoTaikeiRepository implements IKyuyoTaikeiRepository {
  async getAll(companyId: string): Promise<KyuyoTaikei[]> {
    console.debug("MockKyuyoTaikeiRepository.getAll", companyId);
    return [];
  }

  async import(companyId: string, data: File, prefix?: string, suffix?: string): Promise<void> {
    console.debug("MockKyuyoTaikeiRepository.import", companyId, data, prefix, suffix);
  }

  async export(companyId: string): Promise<Blob | null> {
    console.debug("MockKyuyoTaikeiRepository.export", companyId);
    return null;
  }
}
