import type { SaiteiChingin } from "@/models";
import { SaiteiChinginsService } from "@/openapi";

export interface ISaiteiChinginRepository {
  get: () => Promise<SaiteiChingin[]>;
  register(data: File): Promise<void>;
}

export class SaiteiChinginRepository implements ISaiteiChinginRepository {
  public async get(): Promise<SaiteiChingin[]> {
    const res = await SaiteiChinginsService.getSaiteiChinginsService();
    return res as SaiteiChingin[];
  }
  public async register(data: File): Promise<void> {
    await SaiteiChinginsService.postSaiteiChinginsService({ formData: { csvfile: data } });
  }
}
