



































































































import { defineComponent, reactive, PropType, computed } from "@vue/composition-api";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import { EditMode } from "@/composable/utils/const";
import { repositories } from "@/repositories/RepositoryFactory";
import { Company } from "@/models";
import { KyuyoTaikei } from "@/models";

type header = {
  text: string;
  value: string;
};

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    companyList: {
      type: Array as PropType<Company[]>,
      required: true,
    },
  },
  setup(props, context) {
    const state = reactive<{
      headers: header[];
      selectedCompanyId: string;
      kyuyoTaikeiList: Partial<KyuyoTaikei[]>;
      selectedKyuyoTaikeis: KyuyoTaikei[];
      pageNum: number;
    }>({
      headers: [{ text: "給与体系名", value: "name" }],
      selectedCompanyId: "",
      kyuyoTaikeiList: [],
      selectedKyuyoTaikeis: [],
      pageNum: 1,
    });

    const { confirm } = globalConfirm();

    const init = async () => {
      state.selectedCompanyId = "";
      state.selectedKyuyoTaikeis = [];
      state.pageNum = 1;
    };

    const onNext = async () => {
      if (state.selectedCompanyId == "") return;
      state.kyuyoTaikeiList = await repositories.kyuyoTaikeiRepository.getAll(state.selectedCompanyId);
      state.pageNum = 2;
    };

    const selectedCompanyName = computed(() => {
      return props.companyList.find((company) => company.id == state.selectedCompanyId)?.name ?? "";
    });

    const selectedRowKyuyoTaikei = (row: KyuyoTaikei) => {
      state.selectedKyuyoTaikeis.push(row);
    };

    const handleOk = async () => {
      if (
        !(await confirm(`給与体系データを出力しますか？`, {
          title: "給与体系データの出力",
          okLabel: "出力する",
        }))
      ) {
        return false;
      }
      context.emit(
        "export",
        state.selectedCompanyId,
        state.selectedKyuyoTaikeis.map((x) => x.id)
      );
      init();
    };

    const handleClose = () => {
      context.emit("close");
      init();
    };

    return {
      state,
      EditMode,
      init,
      onNext,
      selectedCompanyName,
      selectedRowKyuyoTaikei,
      handleOk,
      handleClose,
    };
  },
});
