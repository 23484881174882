















import { defineComponent, onMounted } from "@vue/composition-api";
import { common } from "@/composable/common";
import AdminTemplate from "@/components/templates/AdminTemplate.vue";
import MenuCategory from "@/components/molecules/MenuCategory.vue";
import MenuItem from "@/components/molecules/MenuItem.vue";
import MenuList from "@/components/molecules/MenuList.vue";
import MenuListItem from "@/components/molecules/MenuListItem.vue";

export default defineComponent({
  components: {
    AdminTemplate,
    MenuCategory,
    MenuItem,
    MenuList,
    MenuListItem,
  },
  setup(props, context) {
    onMounted(async () => {
      const { initApp, info, handleError } = common();
      try {
        info.dispSmallProgress();
        await initApp();
      } catch (e) {
        handleError(e as Error, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
  },
});
