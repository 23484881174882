import { defineModule } from "direct-vuex";
import { moduleGetterContext, moduleActionContext } from "../index";
import { CodeMasters } from "@/models";
import { KeyValue } from "@/types/keyValue";

export interface CodeMastersState {
  codeMasters: CodeMasters | null;
}

const codeMasters = defineModule({
  state: (): CodeMastersState => {
    return {
      codeMasters: null,
    };
  },
  getters: {
    isInited: (...args): boolean => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters !== null;
    },
    jigyoTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.jigyoTypes ?? [];
    },
    torikomiDataTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.torikomiDataTypes ?? [];
    },
    torikomiDataFormatTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.torikomiDataFormatTypes ?? [];
    },
    torikomiDataItemTimeFormatTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.torikomiDataItemTimeFormatTypes ?? [];
    },
    torikomiDataItemTargetTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.torikomiDataItemTargetTypes ?? [];
    },
    todofukenTypes: (...args): KeyValue<number>[] => {
      const { state } = moduleGetterContext(args, codeMasters);
      return state.codeMasters?.todofukenTypes ?? [];
    },
  },
  mutations: {
    SET_CODE_MASTERS(state, param: CodeMasters) {
      state.codeMasters = param;
    },
    CLEAR_CODE_MASTERS(state) {
      state.codeMasters = null;
    },
  },
  actions: {
    async setCodeMasters(context, param: CodeMasters) {
      const { commit } = moduleActionContext(context, codeMasters);
      commit.SET_CODE_MASTERS(param);
    },
    async clearCodeMasters(context) {
      const { commit } = moduleActionContext(context, codeMasters);
      commit.CLEAR_CODE_MASTERS();
    },
  },
  namespaced: true,
});

export default codeMasters;
