/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseCodeMasterParam } from '../models/ResponseCodeMasterParam';
import { request as __request } from '../core/request';

export class CodeMastersService {

    /**
     * コードマスターリストを取得
     * @returns ResponseCodeMasterParam Success
     * @throws ApiError
     */
    public static async getCodeMastersService(): Promise<ResponseCodeMasterParam> {
        const result = await __request({
            method: 'GET',
            path: `/api/codeMasters`,
        });
        return result.body;
    }

}