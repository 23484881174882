import { defineModule } from "direct-vuex";
import { moduleGetterContext, moduleActionContext } from "../index";
import { Token } from "@/models";
const sessionKey = "ABUqVSXEHeAO8siWX4p0yc1gSvkBVdEpXX36sRpP";

export interface AuthState {
  token: string | null;
  displayName: string | null;
}

const auth = defineModule({
  state: (): AuthState => {
    return {
      token: null,
      displayName: null,
    };
  },
  getters: {
    hasToken: (...args): boolean => {
      const { state } = moduleGetterContext(args, auth);
      return state.token !== null;
    },
    isLoggedIn: (...args): boolean => {
      const { state } = moduleGetterContext(args, auth);
      return state.token !== null;
    },
    token: (...args): string => {
      const { state } = moduleGetterContext(args, auth);
      return state.token ?? "";
    },
  },
  mutations: {
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
    LOGIN(state, param: Token) {
      state.token = param.token;
      state.displayName = param.displayName;
    },
    LOGOUT(state) {
      state.token = null;
      state.displayName = null;
    },
  },
  actions: {
    async login(context, param: Token) {
      const { commit } = moduleActionContext(context, auth);
      commit.LOGIN(param);
      // localStorageにtokenを保存
      localStorage.setItem(sessionKey, JSON.stringify(param.token));
    },
    async restore(context) {
      const token = localStorage.getItem(sessionKey);
      if (token) {
        const { commit } = moduleActionContext(context, auth);
        commit.SET_TOKEN(JSON.parse(token));
        return true;
      }
      return false;
    },
    logout(context) {
      const { commit } = moduleActionContext(context, auth);
      commit.LOGOUT();
      // localStorageからtokenを削除
      localStorage.removeItem(sessionKey);
    },
  },
  namespaced: true,
});

export default auth;
