/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class LiteAppliesService {

    /**
     * ライト版トークン発行
     * @returns string Success
     * @throws ApiError
     */
    public static async postLiteAppliesService({
companyId,
}: {
/** 会社ID **/
companyId: string,
}): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/lites/tokens/${companyId}`,
        });
        return result.body;
    }

}