import type { TorikomiDataFormat } from "@/models";
import type { OmitPartial } from "@/types";
import {
  TorikomiDataFormatsService,
  RequestTorikomiDataFormatCreateParam,
  RequestTorikomiDataFormatUpdateParam,
} from "@/openapi";

export interface ITorikomiDataFormatRepository {
  getAll: () => Promise<TorikomiDataFormat[]>;
  template: () => OmitPartial<TorikomiDataFormat, "id">;
  get: (id: string) => Promise<TorikomiDataFormat>;
  register: (data: Partial<TorikomiDataFormat>) => Promise<string>;
  update: (id: string, data: TorikomiDataFormat) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export class TorikomiDataFormatRepository implements ITorikomiDataFormatRepository {
  public async getAll(): Promise<TorikomiDataFormat[]> {
    return (await TorikomiDataFormatsService.getTorikomiDataFormatsService()) as TorikomiDataFormat[];
  }
  public template(): OmitPartial<TorikomiDataFormat, "id"> {
    return {
      id: "",
      name: "",
      torikomiDataTypeId: null,
      torikomiDataFormatTypeId: null,
      companyId: null,
      note: null,
      torikomiDataFormatItems: [],
    };
  }
  public async get(id: string): Promise<TorikomiDataFormat> {
    return (await TorikomiDataFormatsService.getTorikomiDataFormatsService1({ id })) as TorikomiDataFormat;
  }
  public async register(data: Partial<TorikomiDataFormat>): Promise<string> {
    return (await TorikomiDataFormatsService.postTorikomiDataFormatsService({
      requestBody: data as RequestTorikomiDataFormatCreateParam,
    })) as string;
  }
  public async update(id: string, data: TorikomiDataFormat): Promise<void> {
    await TorikomiDataFormatsService.putTorikomiDataFormatsService({
      id,
      requestBody: data as RequestTorikomiDataFormatUpdateParam,
    });
  }
  public async delete(id: string): Promise<void> {
    await TorikomiDataFormatsService.deleteTorikomiDataFormatsService({ id });
  }
}
