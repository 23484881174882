






















import { defineComponent, computed, PropType } from "@vue/composition-api";
import { InputValidationRule } from "vuetify";
import { EditMode } from "@/composable/utils/const";

export default defineComponent({
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    originalValue: {
      type: [String, Number, Boolean],
    },
    editMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
    label: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<InputValidationRule[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    const innerValue = computed({
      get: () => props.value,
      set: (value: string | number | boolean) => {
        if (value != null) {
          context.emit("input", value);
        }
      },
    });
    const bgColor = computed(() => {
      if (props.label !== "" && props.editMode === EditMode.Edit && props.originalValue !== undefined) {
        if (props.value !== props.originalValue) {
          return "blue lighten-5";
        }
      }
      return "";
    });
    const readOnly = computed(() => props.editMode === EditMode.Read);
    const color = computed(() => (props.editMode === EditMode.Read ? "grey darken-1" : "primaryAccent"));
    return { innerValue, bgColor, readOnly, color };
  },
});
