import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import Menu from "@/views/Menu.vue";
import S001 from "@/views/operation/S001.vue";
import S002 from "@/views/operation/S002.vue";
import S003 from "@/views/operation/S003.vue";
import S004 from "@/views/operation/S004.vue";
import S005 from "@/views/operation/S005.vue";
import S006 from "@/views/operation/S006.vue";
import store from "@/store";
import { SiteSetting } from "@/env";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "menu" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "ログイン" },
  },
  {
    path: "/menu",
    name: "menu",
    component: Menu,
    meta: { title: "メニュー", requiresAuth: true },
  },
  {
    path: "/operation/s001",
    name: "s001",
    component: S001,
    meta: { title: "会社契約マスタ", requiresAuth: true },
  },
  {
    path: "/operation/s002",
    name: "s002",
    component: S002,
    meta: { title: "祝日マスタ", requiresAuth: true },
  },
  {
    path: "/operation/s003",
    name: "s003",
    component: S003,
    meta: { title: "お知らせ設定", requiresAuth: true },
  },
  {
    path: "/operation/s004",
    name: "s004",
    component: S004,
    meta: { title: "取込データフォーマット設定", requiresAuth: true },
  },
  {
    path: "/operation/s005",
    name: "s005",
    component: S005,
    meta: { title: "最低賃金マスタ", requiresAuth: true },
  },
  {
    path: "/operation/s006",
    name: "s006",
    component: S006,
    meta: { title: "給与体系複製", requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    if (to.matched.length === 0) {
      throw new Error("未設定のルート");
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.original.direct.getters.auth.hasToken) {
        if (!(await store.original.direct.dispatch.auth.restore())) {
          throw new Error("未ログイン");
        }
      }
    }
  } catch (e) {
    // ログイン画面に遷移
    next({
      name: "login",
    });
  }
  next();
});
router.afterEach((to) => {
  if (to.meta.title) {
    document.title = SiteSetting.name + to.meta.title + " | 勤怠ドライバー ";
  }
});
export default router;
