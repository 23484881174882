




















import { defineComponent, PropType } from "@vue/composition-api";
import { EditMode } from "@/composable/utils/const";

export default defineComponent({
  props: {
    displayMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
  },
  setup() {
    return { EditMode };
  },
});
