














































































































































































































































































































































































































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import SettingTemplate from "@/components/templates/SettingTemplate.vue";
import SearchCompanySidebar from "@/components/organisms/SearchCompanySidebar.vue";
import SettingAction from "@/components/molecules/SettingAction.vue";
import TextInput from "@/components/atoms/TextInput.vue";
import DisplayModeLabel from "@/components/molecules/DisplayModeLabel.vue";
import SelectInput from "@/components/atoms/SelectInput.vue";
import CheckboxInput from "@/components/atoms/CheckboxInput.vue";
import DateInput from "@/components/atoms/DateInput.vue";
import HeaderButton from "@/components/atoms/HeaderButton.vue";
import IconButton from "@/components/atoms/IconButton.vue";
import { Company } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import vuexStore from "@/store";
import clone from "clone";
import { IRepositories } from "@/repositories/RepositoryFactory";
import { SiteSetting } from "@/env";
import type { PickPartial } from "@/types";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    companies: PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">[];
    currentId: string;
    sourceData: Partial<Company>;
    formData: Partial<Company>;
  }>({
    companies: [],
    currentId: "",
    sourceData: {},
    formData: {},
  });

  const init = async () => {
    await getAll();
    if (state.companies.length > 0) {
      await get(state.companies[0].id);
    } else {
      add();
    }
  };
  const getAll = async () => {
    state.companies = await repositories.companyRepository.getAll();
  };
  const get = async (id?: string) => {
    const currentCompany = await repositories.companyRepository.get(id ?? state.currentId);
    state.currentId = currentCompany.id;
    state.sourceData = currentCompany;
    state.formData = clone(currentCompany);
  };
  const getGeneratedCompanyCode = async () => {
    state.formData.companyCode = await repositories.companyRepository.getGeneratedCompanyCode();
  };
  const getLiteAppUrl = async () => {
    if (!state.formData.id) {
      return "";
    }
    return await repositories.liteApplyRepository.token(state.formData.id);
  };
  const checkCompanyCode = async () => {
    if (!state.formData.companyCode || state.formData.companyCode === state.sourceData.companyCode) {
      return false;
    }
    return await repositories.companyRepository.checkCompanyCode(state.formData.companyCode);
  };
  const getEepsilonRegistrationDate = async () => {
    const res = await repositories.companyRepository.getEepsilonRegistrationDate(state.currentId);
    if (res) {
      state.formData.liteVersionPaidApplicationDate = res;
      return true;
    }
    return false;
  };
  const add = () => {
    state.sourceData = repositories.companyRepository.template();
    state.formData = clone(state.sourceData);
    state.currentId = "";
  };
  const save = async () => {
    if (state.currentId === "") {
      state.currentId = await repositories.companyRepository.register(state.formData);
    } else {
      await repositories.companyRepository.update(state.currentId, state.formData as Company);
    }
    await getAll();
    await get();
  };
  const cancel = async () => {
    if (state.currentId === "") {
      if (state.companies.length > 0) {
        await get(state.companies[0].id);
      } else {
        state.formData = clone(state.sourceData);
      }
    } else {
      await get();
    }
  };
  return {
    state,
    init,
    getAll,
    add,
    get,
    save,
    cancel,
    getGeneratedCompanyCode,
    getEepsilonRegistrationDate,
    getLiteAppUrl,
    checkCompanyCode,
  };
};

const view = () => {
  const form = ref<VForm>();
  const viewState = reactive<{
    currentTab: number;
    editMode: EditMode;
    validationMessage: string;
    isRegisting: boolean;
    isRegistSuccess: boolean;
  }>({
    currentTab: 0,
    editMode: EditMode.Read,
    validationMessage: "",
    isRegisting: false,
    isRegistSuccess: false,
  });
  const initView = (isRegistSuccess = false) => {
    viewState.isRegistSuccess = isRegistSuccess;
    viewState.isRegisting = false;
    viewState.currentTab = 0;
    viewState.editMode = EditMode.Read;
    viewState.validationMessage = "";
    form.value?.resetValidation();
  };
  const addView = () => {
    viewState.isRegistSuccess = false;
    viewState.isRegisting = true;
    viewState.editMode = EditMode.Add;
  };
  const editView = () => {
    viewState.isRegistSuccess = false;
    viewState.isRegisting = false;
    viewState.editMode = EditMode.Edit;
  };
  const validate = () => {
    if (form.value?.validate()) {
      return true;
    }
    viewState.validationMessage = "入力内容を確認してください。";
    return false;
  };
  const isEditing = computed(() => viewState.editMode === EditMode.Add || viewState.editMode === EditMode.Edit);
  return { form, viewState, isEditing, initView, addView, editView, validate };
};

// セットアップ
export default defineComponent({
  components: {
    SettingTemplate,
    SearchCompanySidebar,
    SettingAction,
    TextInput,
    SelectInput,
    CheckboxInput,
    DateInput,
    IconButton,
    DisplayModeLabel,
    HeaderButton,
  },
  setup(props, context) {
    const jigyoTypes = computed(() => {
      return vuexStore.getters.codeMasters.jigyoTypes ?? [];
    });
    const { info, repositories, initApp, handleError } = common();
    const { confirmCancelEdit } = globalConfirm();
    const {
      state,
      init,
      add,
      get,
      save,
      cancel,
      getGeneratedCompanyCode,
      getEepsilonRegistrationDate,
      getLiteAppUrl,
      checkCompanyCode,
    } = store(repositories);
    const { form, viewState, isEditing, initView, addView, editView, validate } = view();
    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await initApp();
        await init();
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
    const onAdd = () => {
      add();
      initView();
      addView();
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const onEdit = () => {
      editView();
    };
    const onGenerate = async () => {
      info.dispSmallProgress();
      try {
        await getGeneratedCompanyCode();
      } catch (e) {
        handleError(e, context.root.$router, "コード生成に失敗しました。");
      }
      info.closeProgress();
    };
    const onGetEepsilonRegistrationDate = async () => {
      info.dispSmallProgress();
      try {
        if (!(await getEepsilonRegistrationDate())) {
          info.dispSuccess(
            "イプシロン登録日を取得できませんでした。イプシロン管理画面より定期課金登録情報をご確認ください。"
          );
        }
      } catch (e) {
        handleError(e, context.root.$router, "イプシロン登録日の取得に失敗しました。");
      }
      info.closeProgress();
    };
    const onSave = async () => {
      if (!validate()) {
        return;
      }
      info.dispSmallProgress();
      try {
        if (await checkCompanyCode()) {
          info.dispError("入力された会社コードは既に使われています");
        } else {
          await save();
          info.dispSuccess("更新しました。");
          if (viewState.isRegisting) {
            initView((viewState.isRegistSuccess = true));
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            initView();
          }
        }
      } catch (e) {
        handleError(e, context.root.$router, "データ更新に失敗しました。");
      }
      info.closeProgress();
    };
    const onCancel = async () => {
      cancel();
      initView();
    };
    const onChange = async (value: string) => {
      const orgId = state.currentId;
      state.currentId = value;
      if (!(await confirmCancelEdit(isEditing.value))) {
        state.currentId = orgId;
        return;
      }
      try {
        info.dispSmallProgress();
        if (state.currentId) {
          await get();
        } else {
          add();
        }
        initView();
      } catch (e) {
        // handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    };
    const seikyuSakiEditMode = computed(() => {
      if (!state.formData?.hasSeikyuSaki) {
        return EditMode.Read;
      }
      return viewState.editMode;
    });
    const copyLiteAppUrl = async () => {
      info.dispSmallProgress();
      try {
        if (!navigator.clipboard) {
          throw new Error("未対応のブラウザーです。");
        }
        navigator.clipboard.writeText("");
        const token = await getLiteAppUrl();
        navigator.clipboard.writeText(`${SiteSetting.lightAppUrl}mainregister?u=${encodeURIComponent(token)}`);
      } catch (e) {
        handleError(e, context.root.$router, "URL取得に失敗しました。");
      }
      info.closeProgress();
      info.dispSuccess("コピーしました。");
    };
    const canGetEepsilonRegistrationDate = computed(() => {
      return (
        viewState.editMode === EditMode.Edit &&
        state.formData.liteVersionTrialApplicationDate &&
        !state.formData.liteVersionPaidApplicationDate
      );
    });
    return {
      onAdd,
      onEdit,
      onGenerate,
      onSave,
      onCancel,
      onChange,
      onGetEepsilonRegistrationDate,
      canGetEepsilonRegistrationDate,
      state,
      viewState,
      isEditing,
      form,
      rules,
      seikyuSakiEditMode,
      jigyoTypes,
      EditMode,
      copyLiteAppUrl,
    };
  },
});
