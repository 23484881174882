import { TokenRequest, Token } from "@/models";
import { OpenAPI, TokenService, RequestTokenCreateParam } from "@/openapi";

export interface IAuthRepository {
  login: (data: TokenRequest) => Promise<Token>;
  restore: (token: string) => void;
  logout: () => void;
}

export class AuthRepository implements IAuthRepository {
  public async login(data: TokenRequest): Promise<Token> {
    const res = await TokenService.postTokenService({ requestBody: data as RequestTokenCreateParam });
    // OpenAPIにtokenを設定
    OpenAPI.TOKEN = res.token;
    return res as Token;
  }
  public restore(token: string): void {
    // OpenAPIにtokenを設定
    if (!OpenAPI.TOKEN) {
      OpenAPI.TOKEN = token;
    }
  }
  public logout(): void {
    // OpenAPIのtoken設定を削除
    delete OpenAPI.TOKEN;
  }
}
