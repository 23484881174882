import type { Shukujitsu } from "@/models";
import { IShukujitsuRepository } from "@/repositories";

export class MockShukujitsuRepository implements IShukujitsuRepository {
  private mockData: Shukujitsu[] = [
    {
      id: "id-1",
      date: "2021-01-01",
      name: "元日",
    },
    {
      id: "id-2",
      date: "2021-01-13",
      name: "成人の日",
    },
  ];
  private mockFileData: {
    data?: Blob | null;
  } = {
    data: new Blob(),
  };
  public async get(): Promise<Shukujitsu[]> {
    return this.mockData;
  }
  public async register(data: File): Promise<void> {
    this.mockFileData.data = data;
  }
}
