import type { Shukujitsu } from "@/models";
import { ShukujitsusService } from "@/openapi";

export interface IShukujitsuRepository {
  get: () => Promise<Shukujitsu[]>;
  register(data: File): Promise<void>;
}

export class ShukujitsuRepository implements IShukujitsuRepository {
  public async get(): Promise<Shukujitsu[]> {
    const res = await ShukujitsusService.getShukujitsusService();
    return res as Shukujitsu[];
  }
  public async register(data: File): Promise<void> {
    await ShukujitsusService.postShukujitsusService({ formData: { csvfile: data } });
  }
}
