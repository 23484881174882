/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestTokenCreateParam } from '../models/RequestTokenCreateParam';
import type { ResponseTokenParam } from '../models/ResponseTokenParam';
import { request as __request } from '../core/request';

export class TokenService {

    /**
     * トークンを発行
     * @returns ResponseTokenParam Success
     * @throws ApiError
     */
    public static async postTokenService({
requestBody,
}: {
requestBody?: RequestTokenCreateParam,
}): Promise<ResponseTokenParam> {
        const result = await __request({
            method: 'POST',
            path: `/api/token`,
            body: requestBody,
        });
        return result.body;
    }

}