/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseShukujitsuParam } from '../models/ResponseShukujitsuParam';
import { request as __request } from '../core/request';

export class ShukujitsusService {

    /**
     * 祝日の一覧を取得
     * @returns ResponseShukujitsuParam Success
     * @throws ApiError
     */
    public static async getShukujitsusService(): Promise<Array<ResponseShukujitsuParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/shukujitsus`,
        });
        return result.body;
    }

    /**
     * 祝日のCSV取り込み登録
     * @returns any Success
     * @throws ApiError
     */
    public static async postShukujitsusService({
formData,
}: {
formData?: any,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/shukujitsus`,
            formData: formData,
        });
        return result.body;
    }

}