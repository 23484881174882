/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestTorikomiDataFormatCreateParam } from '../models/RequestTorikomiDataFormatCreateParam';
import type { RequestTorikomiDataFormatUpdateParam } from '../models/RequestTorikomiDataFormatUpdateParam';
import type { ResponseTorikomiDataFormatListParam } from '../models/ResponseTorikomiDataFormatListParam';
import type { ResponseTorikomiDataFormatParam } from '../models/ResponseTorikomiDataFormatParam';
import { request as __request } from '../core/request';

export class TorikomiDataFormatsService {

    /**
     * 取込データフォーマットリストを取得
     * @returns ResponseTorikomiDataFormatListParam Success
     * @throws ApiError
     */
    public static async getTorikomiDataFormatsService(): Promise<Array<ResponseTorikomiDataFormatListParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/torikomiData/formats`,
        });
        return result.body;
    }

    /**
     * 取込データフォーマットを登録
     * @returns string Success
     * @throws ApiError
     */
    public static async postTorikomiDataFormatsService({
requestBody,
}: {
requestBody?: RequestTorikomiDataFormatCreateParam,
}): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/torikomiData/formats`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * 取込データフォーマットを取得
     * @returns ResponseTorikomiDataFormatParam Success
     * @throws ApiError
     */
    public static async getTorikomiDataFormatsService1({
id,
}: {
id: string,
}): Promise<ResponseTorikomiDataFormatParam> {
        const result = await __request({
            method: 'GET',
            path: `/api/torikomiData/formats/${id}`,
        });
        return result.body;
    }

    /**
     * 取込データフォーマットを更新
     * @returns any Success
     * @throws ApiError
     */
    public static async putTorikomiDataFormatsService({
id,
requestBody,
}: {
id: string,
requestBody?: RequestTorikomiDataFormatUpdateParam,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/torikomiData/formats/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * 取込データフォーマットを削除
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteTorikomiDataFormatsService({
id,
}: {
id: string,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/torikomiData/formats/${id}`,
        });
        return result.body;
    }

}