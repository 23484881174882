







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primaryAccent",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const onClick = () => {
      context.emit("click");
    };
    return { onClick };
  },
});
