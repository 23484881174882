import { LiteAppliesService } from "@/openapi";

export interface ILiteApplyRepository {
  token: (companyId: string) => Promise<string>;
}

export class LiteApplyRepository implements ILiteApplyRepository {
  public async token(companyId: string): Promise<string> {
    return await LiteAppliesService.postLiteAppliesService({ companyId });
  }
}
