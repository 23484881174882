





























import { defineComponent, ref, inject } from "@vue/composition-api";
import { RepositoriesKey, IRepositories } from "@/repositories/RepositoryFactory";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import store from "@/store";

export default defineComponent({
  props: {
    isSubPage: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { confirmCancelEdit } = globalConfirm();
    const authRepository = inject<IRepositories>(RepositoriesKey)?.authRepository;
    if (!authRepository) {
      throw new Error("リポジトリの初期化に失敗しました。");
    }
    const drawer = ref(false);
    const logout = async () => {
      if (!(await confirmCancelEdit(props.isEditing))) {
        return;
      }
      authRepository.logout();
      store.dispatch.auth.logout();
      context.root.$router.push({ name: "login" });
    };
    const top = async () => {
      if (!(await confirmCancelEdit(props.isEditing))) {
        return;
      }
      context.root.$router.push({ name: "menu" });
    };
    const back = async () => {
      if (!(await confirmCancelEdit(props.isEditing))) {
        return;
      }
      context.root.$router.back();
    };
    return { drawer, logout, top, back };
  },
});
