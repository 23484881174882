















































import { defineComponent, reactive, computed, PropType } from "@vue/composition-api";
import IconButton from "@/components/atoms/IconButton.vue";
import { InputValidationRule } from "vuetify";
import { EditMode } from "@/composable/utils/const";
import { rules } from "@/composable/utils/rules";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    IconButton,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    originalValue: {
      type: String,
    },
    editMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
    label: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<InputValidationRule[]>,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar",
    },
    appendOuter: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const state = reactive({
      menu: false,
      filterBtnTTip: false,
    });
    const innerValue = computed<string>({
      get: () => (dayjs(props.value).isValid() ? dayjs(props.value).format("YYYY-MM-DD") : ""),
      set: (value: string) => {
        if (value) {
          context.emit("input", dayjs(value).isValid() ? value : null);
        } else {
          context.emit("input", null);
        }
      },
    });
    const bgColor = computed(() => {
      if (
        props.editMode === EditMode.Edit &&
        props.originalValue !== undefined &&
        !(props.originalValue === null && props.value === null)
      ) {
        if (!dayjs(props.value).isSame(dayjs(props.originalValue))) {
          return "blue lighten-5";
        }
      }
      return "";
    });
    const input = (value: string) => {
      if (
        value?.match(
          /^((1|2)\d{3}[/-](0?[1-9]|1[0-2])[/-](0?[1-9]|[12][0-9]|3[01]))|(1|2)\d{3}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/
        )
      ) {
        if (dayjs(value).isValid()) {
          context.emit("input", dayjs(value).format("YYYY-MM-DD"));
        }
      }
    };
    const clear = () => {
      context.emit("input", null);
    };
    const readOnly = computed(() => props.editMode === EditMode.Read);
    const color = computed(() => (props.editMode === EditMode.Read ? "grey darken-1" : "primaryAccent"));
    const innerRules = [...props.rules, rules.dateString];
    return { state, innerValue, input, clear, bgColor, readOnly, color, innerRules };
  },
});
