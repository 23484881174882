/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestCompanyCreateParam } from '../models/RequestCompanyCreateParam';
import type { RequestCompanyUpdateParam } from '../models/RequestCompanyUpdateParam';
import type { ResponseCompanyListParam } from '../models/ResponseCompanyListParam';
import type { ResponseCompanyParam } from '../models/ResponseCompanyParam';
import type { ResponseEpsilonRegistrationDateParam } from '../models/ResponseEpsilonRegistrationDateParam';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * 会社の一覧を取得
     * @returns ResponseCompanyListParam Success
     * @throws ApiError
     */
    public static async getCompaniesService(): Promise<Array<ResponseCompanyListParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/companies`,
        });
        return result.body;
    }

    /**
     * 会社契約を登録
     * @returns string Success
     * @throws ApiError
     */
    public static async postCompaniesService({
requestBody,
}: {
requestBody?: RequestCompanyCreateParam,
}): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/companies`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * 会社契約の取得
     * @returns ResponseCompanyParam Success
     * @throws ApiError
     */
    public static async getCompaniesService1({
id,
}: {
id: string,
}): Promise<ResponseCompanyParam> {
        const result = await __request({
            method: 'GET',
            path: `/api/companies/${id}`,
        });
        return result.body;
    }

    /**
     * 会社契約の更新
     * @returns any Success
     * @throws ApiError
     */
    public static async putCompaniesService({
id,
requestBody,
}: {
id: string,
requestBody?: RequestCompanyUpdateParam,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/companies/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * 新規会社コードの生成
     * @returns string Success
     * @throws ApiError
     */
    public static async getCompaniesService2(): Promise<string> {
        const result = await __request({
            method: 'GET',
            path: `/api/companies/generatedCompanyCode`,
        });
        return result.body;
    }

    /**
     * 会社コードの存在有無
     * @returns boolean Success
     * @throws ApiError
     */
    public static async getCompaniesService3({
companyCode,
}: {
/** 会社コード **/
companyCode: string,
}): Promise<boolean> {
        const result = await __request({
            method: 'GET',
            path: `/api/companies/companyCodes/${companyCode}`,
        });
        return result.body;
    }

    /**
     * イプシロン登録日確認
     * @returns ResponseEpsilonRegistrationDateParam Success
     * @throws ApiError
     */
    public static async getCompaniesService4({
id,
}: {
id: string,
}): Promise<ResponseEpsilonRegistrationDateParam> {
        const result = await __request({
            method: 'GET',
            path: `/api/companies/${id}/epsilonRegistrationDate`,
        });
        return result.body;
    }

}