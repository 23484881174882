































































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import AdminTemplate from "@/components/templates/AdminTemplate.vue";
import DataTable from "@/components/molecules/DataTable.vue";
import FileInput from "@/components/atoms/FileInput.vue";
import { Shukujitsu } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import { DataTableHeader } from "vuetify/types";
import { IRepositories } from "@/repositories/RepositoryFactory";
import dayjs from "dayjs";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    shukujitsus: Shukujitsu[];
    formData: {
      uploadData: File | null;
      uploadFileName: string;
    };
  }>({
    shukujitsus: [],
    formData: {
      uploadData: null,
      uploadFileName: "",
    },
  });

  const init = async () => {
    await get();
  };
  const get = async () => {
    state.shukujitsus = await repositories.shukujitsuRepository.get();
  };
  const upload = async () => {
    if (state.formData.uploadData) {
      await repositories.shukujitsuRepository.register(state.formData.uploadData);
      state.formData.uploadData = null;
      state.formData.uploadFileName = "";
    }
  };
  return { state, init, get, upload };
};

// セットアップ
export default defineComponent({
  components: {
    AdminTemplate,
    DataTable,
    FileInput,
  },
  setup(props, context) {
    const { info, repositories, initApp, handleError } = common();
    const { confirm } = globalConfirm();
    const { state, init, get, upload } = store(repositories);
    const form = ref<VForm>();
    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await initApp();
        await init();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
    const onUpload = async () => {
      if (form.value?.validate()) {
        if (
          !(await confirm("祝日データを取り込みますか?", { title: "祝日データ取込", okLabel: "祝日データを取り込む" }))
        ) {
          return false;
        }
        try {
          info.dispSmallProgress();
          await upload();
          await get();
          form.value?.resetValidation();
          info.dispSuccess("取込に成功しました。");
        } catch (e) {
          handleError(e, context.root.$router, "取込に失敗しました。");
        }
        info.closeProgress();
      }
    };
    const tableHeaders: DataTableHeader[] = [
      { text: "日付", value: "date" },
      { text: "国民の祝日名称	", value: "name" },
    ];

    const tableData = computed(() => {
      return state.shukujitsus.map((x) => ({
        date: dayjs(x.date).format("YYYY/MM/DD"),
        name: x.name,
      }));
    });

    return {
      state,
      form,
      rules,
      onUpload,
      tableHeaders,
      tableData,
      EditMode,
    };
  },
});
