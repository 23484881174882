import type { SaiteiChingin } from "@/models";
import { ISaiteiChinginRepository } from "@/repositories";

export class MockSaiteiChinginRepository implements ISaiteiChinginRepository {
  private mockData: SaiteiChingin[] = [
    {
      id: "id-1",
      todofukenId: 1,
      startDate: "2021-01-01",
      jikanGaku: 123,
    },
    {
      id: "id-2",
      todofukenId: 2,
      startDate: "2021-01-13",
      jikanGaku: 321,
    },
  ];
  private mockFileData: {
    data?: Blob | null;
  } = {
    data: new Blob(),
  };
  public async get(): Promise<SaiteiChingin[]> {
    return this.mockData;
  }
  public async register(data: File): Promise<void> {
    this.mockFileData.data = data;
  }
}
