/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseSaiteiChinginParam } from '../models/ResponseSaiteiChinginParam';
import { request as __request } from '../core/request';

export class SaiteiChinginsService {

    /**
     * 最低賃金の一覧を取得
     * @returns ResponseSaiteiChinginParam Success
     * @throws ApiError
     */
    public static async getSaiteiChinginsService(): Promise<Array<ResponseSaiteiChinginParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/saiteiChingins`,
        });
        return result.body;
    }

    /**
     * 最低賃金のCSV取り込み登録
     * @returns any Success
     * @throws ApiError
     */
    public static async postSaiteiChinginsService({
formData,
}: {
formData?: any,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/saiteiChingins`,
            formData: formData,
        });
        return result.body;
    }

}