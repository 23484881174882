import { TokenRequest, Token } from "@/models";
import { IAuthRepository } from "@/repositories";

export class MockAuthRepository implements IAuthRepository {
  private mockData: Token = {
    token: "token",
    displayName: "ユーザー",
  };

  public async login(data: TokenRequest): Promise<Token> {
    if (data.userCode !== "logikintai" || data.password !== "gl23fw@90") {
      throw new Error("ログインに失敗しました");
    }
    return this.mockData;
  }

  public restore(token: string): void {
    this.mockData.token = token;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public logout(): void {}
}
