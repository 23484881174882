/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseKyuyoTaikeiParam } from '../models/ResponseKyuyoTaikeiParam';
import { request as __request } from '../core/request';

export class KyuyoTaikeisService {

    /**
     * 給与体系と紐づく給与項目を出力
     * 出力する給与体系IDコレクション数がクエリ文字列の最大長を超える可能性があるため、
 * PUTメソッドを使用する。
     * @returns any Success
     * @throws ApiError
     */
    public static async putKyuyoTaikeisService({
companyId,
requestBody,
}: {
companyId?: string,
requestBody?: Array<string>,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/kyuyos/taikeis/exports`,
            query: {
                'companyId': companyId,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * 給与体系と紐づく給与項目の取込
     * @returns any Success
     * @throws ApiError
     */
    public static async postKyuyoTaikeisService({
companyId,
prefix,
suffix,
formData,
}: {
companyId?: string,
prefix?: string,
suffix?: string,
formData?: any,
}): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/kyuyos/taikeis/import`,
            query: {
                'companyId': companyId,
                'prefix': prefix,
                'suffix': suffix,
            },
            formData: formData,
        });
        return result.body;
    }

    /**
     * 給与体系リストを取得
     * @returns ResponseKyuyoTaikeiParam Success
     * @throws ApiError
     */
    public static async getKyuyoTaikeisService({
companyId,
}: {
companyId?: string,
}): Promise<Array<ResponseKyuyoTaikeiParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/kyuyos/taikeis`,
            query: {
                'companyId': companyId,
            },
        });
        return result.body;
    }

}