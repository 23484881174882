


















































import { defineComponent, reactive, computed, PropType } from "@vue/composition-api";
import type { KeyValue } from "@/types/keyValue";

export default defineComponent({
  props: {
    headers: Array,
    value: {
      type: Array as PropType<Array<string | number>>,
      default: () => [],
    },
    items: {
      type: Array as PropType<KeyValue<string | number>[]>,
      required: true,
    },
    height: {
      type: String,
      default: "352",
    },
    hideDetailHeader: {
      type: Boolean,
      default: false,
    },
    hideDetailFooter: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    serverPage: {
      type: Number,
      default: null,
    },
    serverPageCount: {
      type: Number,
      default: null,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    footerProps: {
      type: Object,
      default: () => {
        return {
          "items-per-page-text": "1ページの件数：",
          "items-per-page-options": [-1, 5, 10, 15, 20, 30],
        };
      },
    },
    tableTopClass: {
      type: String,
      default: "",
    },
    dataTableClass: {
      type: String,
      default: "",
    },
    rowClass: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const state = reactive({
      pagination: {
        page: 1,
        pageCount: 0,
      },
    });

    const innerValue = computed({
      get: () => props.value,
      set: (value: Array<string | number>) => {
        context.emit("input", value);
        context.emit("change");
      },
    });

    const page = computed<number>({
      get: () => {
        return props.serverPage != null ? props.serverPage : state.pagination.page;
      },
      set: (value: number) => {
        if (props.serverPage != null) {
          context.emit("movepage", value);
        } else {
          state.pagination.page = value;
        }
      },
    });

    const pageCount = computed(() => {
      return props.serverPageCount != null ? props.serverPageCount : state.pagination.pageCount;
    });

    const onClick = (item: KeyValue<string | number>) => {
      if (props.disabled) {
        return;
      }
      context.emit("click", item.id);
    };

    const getDakokuTypeColor = (dakokuType: string) => {
      if (dakokuType === "出勤") return "primaryAccent";
      else if (dakokuType === "退勤") return "red";
      else if (dakokuType === "休憩開始" || dakokuType === "休憩終了") return "teal";
    };

    return { state, innerValue, page, pageCount, onClick, getDakokuTypeColor };
  },
});
