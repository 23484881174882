










































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { common } from "@/composable/common";
import { TokenRequest } from "@/models";
import { rules } from "@/composable/utils/rules";
import { crypto } from "@/composable/utils/crypto";
import { VForm } from "@/types/vuetify/vForm";
import store from "@/store";

type isSave = {
  userCode: boolean;
  password: boolean;
};

// view関係
const view = () => {
  const form = ref<VForm>();
  const viewState = reactive<{
    formData: TokenRequest;
    isSave: isSave;
    validationMessage: string;
  }>({
    formData: {
      userCode: "",
      password: "",
    },
    isSave: {
      userCode: false,
      password: false,
    },
    validationMessage: "",
  });
  const validate = () => {
    if (form.value?.validate()) {
      return true;
    }
    viewState.validationMessage = "入力内容を確認してください。";
    return false;
  };

  // ID保存
  const { setLSItem, getLSItem } = ls();
  const saveIds = () => {
    const data: Partial<TokenRequest> = {};
    if (viewState.isSave.userCode) data.userCode = viewState.formData.userCode;
    if (viewState.isSave.password) data.password = viewState.formData.password;
    setLSItem(data, viewState.isSave);
  };
  const loadIds = () => {
    const { data, isSave } = getLSItem();
    viewState.formData.userCode = data.userCode ?? "";
    viewState.formData.password = data.password ?? "";
    viewState.isSave.userCode = isSave?.userCode ?? false;
    viewState.isSave.password = isSave?.password ?? false;
  };
  return { viewState, validate, form, saveIds, loadIds };
};

// localStorage
const ls = () => {
  type LSData = { a?: string; b?: string; c: isSave };
  const KEY = "gFdQovH46oiyhs4AwLQPj6RlFqRG0R6vfVFSjnGx";
  const setLSItem = (data: Partial<TokenRequest>, isSave: isSave) => {
    const d: LSData = {
      c: isSave,
    };
    if (data.userCode) d.a = data.userCode;
    if (data.password) d.b = data.password;
    if (Object.keys(d).length > 1) {
      localStorage.setItem(KEY, crypto.encrypt(JSON.stringify(d)));
    } else {
      localStorage.removeItem(KEY);
    }
  };
  const getLSItem = () => {
    const lsItem = localStorage.getItem(KEY);
    const data: Partial<TokenRequest> = {};
    let isSave: isSave | null = null;
    if (lsItem) {
      const d = JSON.parse(crypto.decrypt(lsItem)) as LSData;
      if (d.a) data.userCode = d.a;
      if (d.b) data.password = d.b;
      if (d.c) isSave = d.c;
    }
    return { data, isSave };
  };
  return { setLSItem, getLSItem };
};

// セットアップ
export default defineComponent({
  setup(props, context) {
    const { repositories, info } = common();
    const { viewState, validate, form, saveIds, loadIds } = view();
    onMounted(() => {
      loadIds();
    });
    const login = async () => {
      if (!validate()) {
        return;
      }
      info.dispLargeProgress();
      try {
        const res = await repositories.authRepository.login(viewState.formData);
        store.dispatch.auth.login(res);
        saveIds();
        store.dispatch.codeMasters.clearCodeMasters();
        context.root.$router.push({ name: "menu" });
      } catch (e) {
        info.dispError("ログインに失敗しました。");
      }
      info.closeProgress();
    };
    return {
      login,
      viewState,
      rules,
      form,
    };
  },
});
