
























































import { defineComponent, reactive, PropType, computed, watch } from "@vue/composition-api";
import SelectInput from "@/components/atoms/SelectInput.vue";
import type { KeyValue } from "@/types/keyValue";
import { TorikomiDataFormat } from "@/models";

export default defineComponent({
  components: {
    SelectInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array as PropType<Readonly<Partial<TorikomiDataFormat>>[]>,
      required: true,
    },
    torikomiDataTypes: {
      type: Array as PropType<KeyValue<number>[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
  },
  setup(props, context) {
    const state = reactive({
      searchParam: {
        name: "",
        torikomiDataTypeId: null,
      },
      page: 1,
      innerValue: props.value,
    });

    const filteredTorikomiDataFormats = computed(() => {
      return props.items.filter((x) => {
        if (state.searchParam.name) {
          if (!x.name?.match(state.searchParam.name)) return false;
        }
        if (state.searchParam.torikomiDataTypeId != null) {
          console.log(state.searchParam.torikomiDataTypeId);
          console.log(x.torikomiDataTypeId);
          if (x.torikomiDataTypeId !== state.searchParam.torikomiDataTypeId) return false;
        }
        return true;
      });
    });

    const paginatedTorikomiDataFormats = computed(() => {
      return filteredTorikomiDataFormats.value.filter(
        (x, index) =>
          index >= (state.page - 1) * props.itemsPerPage &&
          index < (state.page - 1) * props.itemsPerPage + props.itemsPerPage
      );
    });

    // props.value初回設定時に選択状態を反映
    watch(
      () => props.value,
      () => {
        state.innerValue = props.value;
      }
    );

    const pageCount = computed(() => {
      return Math.ceil(filteredTorikomiDataFormats.value.length / props.itemsPerPage);
    });

    const onChange = () => {
      if (state.innerValue != null) {
        context.emit("input", state.innerValue);
        context.emit("change");
      }
    };
    const onPaginate = () => {
      state.innerValue = props.value;
    };
    return { state, paginatedTorikomiDataFormats, pageCount, onChange, onPaginate };
  },
});
