import type { CodeMasters } from "@/models";
import { CodeMastersService } from "@/openapi";

export interface ICodeMastersRepository {
  get: () => Promise<CodeMasters>;
}

export class CodeMastersRepository implements ICodeMastersRepository {
  public async get(): Promise<CodeMasters> {
    return (await CodeMastersService.getCodeMastersService()) as CodeMasters;
  }
}
