/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";

export interface AppState {
  dispFullscreenLoading: boolean;
  dispSmallLoading: boolean;
  loadingMessage: string;
  dispSnackbar: boolean;
  snackbarMessage: string;
  snackbarType: string;
  [key: string]: any;
}

const App = defineModule({
  state: (): AppState => {
    return {
      dispFullscreenLoading: false,
      dispSmallLoading: false,
      loadingMessage: "",
      dispSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
    };
  },
  getters: {},
  mutations: {
    SET_VALUE(state, param: { name: string; value: any }) {
      state[param.name] = param.value;
    },
  },
  actions: {
    async dispLargeProgress(context, message: string | null = null) {
      const { commit } = moduleActionContext(context, App);
      commit.SET_VALUE({ name: "loadingMessage", value: message ?? "" });
      commit.SET_VALUE({ name: "dispFullscreenLoading", value: true });
    },
    async dispSmallProgress(context, message: string | null = null) {
      const { commit } = moduleActionContext(context, App);
      commit.SET_VALUE({ name: "loadingMessage", value: message ?? "" });
      commit.SET_VALUE({ name: "dispSmallLoading", value: true });
    },
    async closeProgress(context) {
      const { commit } = moduleActionContext(context, App);
      commit.SET_VALUE({ name: "loadingMessage", value: "" });
      commit.SET_VALUE({ name: "dispFullscreenLoading", value: false });
      commit.SET_VALUE({ name: "dispSmallLoading", value: false });
    },
    async dispSuccess(context, message: string | null = null) {
      const { commit } = moduleActionContext(context, App);
      commit.SET_VALUE({ name: "snackbarMessage", value: message ?? "" });
      commit.SET_VALUE({ name: "dispSnackbar", value: true });
      commit.SET_VALUE({ name: "snackbarType", value: "success" });
    },
    async dispError(context, message: string | null = null) {
      const { commit } = moduleActionContext(context, App);
      commit.SET_VALUE({ name: "snackbarMessage", value: message ?? "" });
      commit.SET_VALUE({ name: "dispSnackbar", value: true });
      commit.SET_VALUE({ name: "snackbarType", value: "error" });
    },
    async executeLoadingAction(context, action: () => Promise<void>) {
      const { commit } = moduleActionContext(context, App);
      try {
        commit.SET_VALUE({ name: "dispFullscreenLoading", value: true });
        await action();
      } catch (e) {
        commit.SET_VALUE({
          name: "snackbarMessage",
          value: `エラーが発生しました。${e}`,
        });
        commit.SET_VALUE({ name: "dispSnackbar", value: true });
        commit.SET_VALUE({ name: "snackbarType", value: "error" });
      } finally {
        commit.SET_VALUE({ name: "dispFullscreenLoading", value: false });
      }
    },
  },
  namespaced: true,
});

export default App;
