








import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    to: {
      type: [String, Object],
    },
  },
});
