





























































































































import { defineComponent, reactive, PropType, computed } from "@vue/composition-api";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import { Company } from "@/models";
import FileInput from "@/components/atoms/FileInput.vue";
import TextInput from "@/components/atoms/TextInput.vue";
import { EditMode } from "@/composable/utils/const";
import { rules } from "@/composable/utils/rules";

export default defineComponent({
  components: {
    TextInput,
    FileInput,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    companyList: {
      type: Array as PropType<Company[]>,
      required: true,
    },
  },
  setup(props, context) {
    const state = reactive<{
      importData: {
        uploadData: File | null;
        prefix: string;
        suffix: string;
        uploadFileName: string;
        companyId: string;
      };
      companyListData: Company[];

      pageNum: number;
    }>({
      importData: {
        uploadData: null,
        prefix: "",
        suffix: "",
        uploadFileName: "",
        companyId: "",
      },
      companyListData: props.companyList,

      pageNum: 1,
    });

    const { confirm } = globalConfirm();

    const init = async () => {
      state.importData = {
        uploadData: null,
        prefix: "",
        suffix: "",
        uploadFileName: "",
        companyId: "",
      };
      state.pageNum = 1;
    };

    const selectedCompanyName = computed(() => {
      return props.companyList.find((company) => company.id == state.importData.companyId)?.name ?? "";
    });

    const handleOk = async () => {
      if (state.importData.companyId == null || state.importData.uploadData == null) return;
      if (
        !(await confirm(`給与体系と給与項目をファイルから取り込みますか？`, {
          title: "給与体系と給与項目の取込",
          okLabel: "取り込む",
        }))
      ) {
        return false;
      }
      context.emit("import", state.importData);
      init();
    };

    const handleClose = () => {
      context.emit("close");
      init();
    };

    const kyuyoTaikeiNameSample = computed(() => {
      return `${state.importData.prefix ?? ""}{給与体系名}${state.importData.suffix ?? ""}`;
    });

    return {
      state,
      init,
      EditMode,
      rules,
      kyuyoTaikeiNameSample,
      selectedCompanyName,
      handleOk,
      handleClose,
    };
  },
});
