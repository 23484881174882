/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RequestOshiraseCreateParam } from '../models/RequestOshiraseCreateParam';
import type { RequestOshiraseUpdateParam } from '../models/RequestOshiraseUpdateParam';
import type { ResponseOshiraseParam } from '../models/ResponseOshiraseParam';
import { request as __request } from '../core/request';

export class OshirasesService {

    /**
     * お知らせの一覧を取得
     * @returns ResponseOshiraseParam Success
     * @throws ApiError
     */
    public static async getOshirasesService(): Promise<Array<ResponseOshiraseParam>> {
        const result = await __request({
            method: 'GET',
            path: `/api/oshirases`,
        });
        return result.body;
    }

    /**
     * お知らせを登録
     * @returns string Success
     * @throws ApiError
     */
    public static async postOshirasesService({
requestBody,
}: {
requestBody?: RequestOshiraseCreateParam,
}): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/oshirases`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * お知らせの取得
     * @returns ResponseOshiraseParam Success
     * @throws ApiError
     */
    public static async getOshirasesService1({
id,
}: {
id: string,
}): Promise<ResponseOshiraseParam> {
        const result = await __request({
            method: 'GET',
            path: `/api/oshirases/${id}`,
        });
        return result.body;
    }

    /**
     * お知らせの更新
     * @returns any Success
     * @throws ApiError
     */
    public static async putOshirasesService({
id,
requestBody,
}: {
id: string,
requestBody?: RequestOshiraseUpdateParam,
}): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/oshirases/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * お知らせの削除
     * @returns any Success
     * @throws ApiError
     */
    public static async deleteOshirasesService({
id,
}: {
id: string,
}): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/oshirases/${id}`,
        });
        return result.body;
    }

}