















































import { defineComponent, reactive, ref } from "@vue/composition-api";
import { VForm } from "@/types/vuetify/vForm";

export default defineComponent({
  props: {
    text: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primaryAccent",
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    buttonClass: {
      type: String as () => string | null,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    btnMinWidth: {
      type: String,
      default: "",
    },
    btnWidth: {
      type: String,
      default: "",
    },
    btnHeight: {
      type: String,
      default: "",
    },
    btnIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "960",
    },
    cancelLabel: {
      type: String,
      default: "キャンセル",
    },
    okLabel: {
      type: String,
      default: "OK",
    },
    isCancelable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const state = reactive({
      dialogVisible: false,
    });
    const form = ref<VForm>();

    const openDialog = () => {
      context.emit("open");
      state.dialogVisible = true;
    };

    const closeDialog = () => {
      state.dialogVisible = false;
    };

    const onClick = () => {
      if (!form.value?.validate()) {
        return;
      }
      context.emit("click");
      closeDialog();
    };

    const onCancel = () => {
      context.emit("cancel");
      closeDialog();
    };

    return { state, openDialog, onClick, onCancel, form };
  },
});
