import { KeyValue } from "@/types/keyValue";

export enum EditMode {
  Read,
  Edit,
  Add,
}

export enum KanriShaTypeId {
  // 選任されていない
  None = 1,
  // 補助者
  HojoSha = 2,
  // 管理者
  KanriSha = 3,
}

export enum TodofukenType {
  // 北海道
  Hokkaido = 1,
  // 青森県
  Aomori = 2,
  // 岩手県
  Iwate = 3,
  // 宮城県
  Miyagi = 4,
  // 秋田県
  Akita = 5,
  // 山形県
  Yamagata = 6,
  // 福島県
  Fukushima = 7,
  // 茨城県
  Ibaraki = 8,
  // 栃木県
  Tochigi = 9,
  // 群馬県
  Gunma = 10,
  // 埼玉県
  Saitama = 11,
  // 千葉県
  Chiba = 12,
  // 東京都
  Tokyo = 13,
  // 神奈川県
  Kanagawa = 14,
  // 新潟県
  Niigata = 15,
  // 富山県
  Toyama = 16,
  // 石川県
  Ishikawa = 17,
  // 福井県
  Fukui = 18,
  // 山梨県
  Yamanashi = 19,
  // 長野県
  Nagano = 20,
  // 岐阜県
  Gifu = 21,
  // 静岡県
  Shizuoka = 22,
  // 愛知県
  Aichi = 23,
  // 三重県
  Mie = 24,
  // 滋賀県
  Shiga = 25,
  // 京都府
  Kyoto = 26,
  // 大阪府
  Osaka = 27,
  // 兵庫県
  Hyogo = 28,
  // 奈良県
  Nara = 29,
  // 和歌山県
  Wakayama = 30,
  // 鳥取県
  Tottori = 31,
  // 島根県
  Shimane = 32,
  // 岡山県
  Okayama = 33,
  // 広島県
  Hiroshima = 34,
  // 山口県
  Yamaguchi = 35,
  // 徳島県
  Tokushima = 36,
  // 香川県
  Kagawa = 37,
  // 愛媛県
  Ehime = 38,
  // 高知県
  Kochi = 39,
  // 福岡
  Fukuoka = 40,
  // 佐賀県
  Saga = 41,
  // 長崎県
  Nagasaki = 42,
  // 熊本県
  Kumamoto = 43,
  // 大分県
  Oita = 44,
  // 宮崎県
  Miyazaki = 45,
  // 鹿児島県
  Kagoshima = 46,
  // 沖縄県
  Okinawa = 47,
}

export enum ShokushuTypeId {
  // 運転職
  UntenShoku = 1,
  // 事務職
  JimuShoku = 2,
  // 荷役職
  NiyakuShoku = 3,
  // 整備技能職
  SeibiginoShoku = 4,
  // 管理職
  KanriShoku = 5,
  // 営業職
  EigyoShoku = 6,
}

export enum CompanyKeiyakuStatus {
  通常版利用中 = 1,
  無料ライト版利用中 = 2,
  無料ライト版期限切れ = 3,
  有料ライト版利用中 = 4,
  利用終了 = 5,
}

export const shokushuTypeList: KeyValue<number>[] = [
  { id: ShokushuTypeId.JimuShoku, name: "事務職" },
  { id: ShokushuTypeId.EigyoShoku, name: "営業職" },
  { id: ShokushuTypeId.KanriShoku, name: "管理職" },
  { id: ShokushuTypeId.NiyakuShoku, name: "荷役職" },
  { id: ShokushuTypeId.SeibiginoShoku, name: "整備技能職" },
  { id: ShokushuTypeId.UntenShoku, name: "運転職" },
];
