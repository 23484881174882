























import { defineComponent, computed, PropType, ref, watch } from "@vue/composition-api";
import { InputValidationRule } from "vuetify";
import { EditMode } from "@/composable/utils/const";

export default defineComponent({
  props: {
    value: {
      default: null,
    },
    fileName: {
      type: String,
      default: "",
    },
    originalFileName: {
      type: String,
    },
    editMode: {
      type: Number as PropType<EditMode>,
      default: EditMode.Read,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "",
    },
    cssClass: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<InputValidationRule[]>,
      default: () => [],
    },
  },
  setup(props, context) {
    watch(
      () => props.value,
      () => {
        if (props.value == null && fileInput.value) {
          fileInput.value.value = "";
        }
      }
    );
    const innerFileName = computed({
      get: () => props.fileName,
      set: (value: string) => {
        context.emit("update:fileName", value);
      },
    });
    const fileInput = ref<HTMLInputElement>();
    const readOnly = computed(() => props.editMode === EditMode.Read);
    const onClick = () => {
      if (!readOnly.value) {
        fileInput.value?.click();
      }
    };
    const onInput = () => {
      const files = fileInput.value?.files;
      if (files && files.length > 0) {
        innerFileName.value = files[0].name ?? "";
        context.emit("input", files[0]);
      }
    };
    const onClear = () => {
      innerFileName.value = "";
      context.emit("input", null);
    };
    const bgColor = computed(() => {
      if (props.editMode === EditMode.Edit && props.fileName != null && props.originalFileName !== undefined) {
        if (innerFileName.value !== props.originalFileName) {
          return "blue lighten-5";
        }
      }
      return "";
    });
    return { innerFileName, bgColor, readOnly, fileInput, onClick, onInput, onClear };
  },
});
