
































































































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import SettingTemplate from "@/components/templates/SettingTemplate.vue";
import RecordList from "@/components/molecules/RecordList.vue";
import SettingAction from "@/components/molecules/SettingAction.vue";
import TextInput from "@/components/atoms/TextInput.vue";
import TextareaInput from "@/components/atoms/TextareaInput.vue";
import CheckboxInput from "@/components/atoms/CheckboxInput.vue";
import IconButton from "@/components/atoms/IconButton.vue";
import DisplayModeLabel from "@/components/molecules/DisplayModeLabel.vue";
import HeaderButton from "@/components/atoms/HeaderButton.vue";
import { Oshirase } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import { IRepositories } from "@/repositories/RepositoryFactory";
import clone from "clone";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    oshirases: Partial<Oshirase>[];
    currentId: string;
    sourceData: Partial<Oshirase>;
    formData: Partial<Oshirase>;
  }>({
    oshirases: [],
    currentId: "",
    sourceData: {},
    formData: {},
  });

  const init = async () => {
    await getAll();
    if (state.oshirases.length > 0) {
      await get(state.oshirases[0].id);
    } else {
      add();
    }
  };
  const getAll = async () => {
    state.oshirases = await repositories.oshiraseRepository.getAll();
  };
  const get = async (id?: string) => {
    const currentOshirase = await repositories.oshiraseRepository.get(id ?? state.currentId);
    state.currentId = currentOshirase.id;
    state.sourceData = currentOshirase;
    state.formData = clone(currentOshirase);
  };
  const add = () => {
    state.sourceData = repositories.oshiraseRepository.template();
    state.formData = clone(state.sourceData);
    state.currentId = "";
  };
  const save = async () => {
    if (state.currentId === "") {
      state.currentId = await repositories.oshiraseRepository.register(state.formData);
    } else {
      await repositories.oshiraseRepository.update(state.currentId, state.formData as Oshirase);
    }
    await getAll();
    await get();
  };
  const del = async () => {
    await repositories.oshiraseRepository.delete(state.currentId);
    state.currentId = "";
    await init();
  };
  const cancel = async () => {
    if (state.currentId === "") {
      if (state.oshirases.length > 0) {
        await get(state.oshirases[0].id);
      } else {
        state.formData = clone(state.sourceData);
      }
    } else {
      await get();
    }
  };

  return {
    state,
    init,
    getAll,
    add,
    get,
    save,
    del,
    cancel,
  };
};

const view = () => {
  const form = ref<VForm>();
  const viewState = reactive<{
    editMode: EditMode;
    validationMessage: string;
  }>({
    editMode: EditMode.Read,
    validationMessage: "",
  });
  const initView = () => {
    viewState.editMode = EditMode.Read;
    viewState.validationMessage = "";
    form.value?.resetValidation();
  };
  const addView = () => {
    viewState.editMode = EditMode.Add;
  };
  const editView = () => {
    viewState.editMode = EditMode.Edit;
  };
  const validate = () => {
    if (form.value?.validate()) {
      return true;
    }
    viewState.validationMessage = "入力内容を確認してください。";
    return false;
  };
  const isEditing = computed(() => viewState.editMode === EditMode.Add || viewState.editMode === EditMode.Edit);
  return { form, viewState, isEditing, initView, addView, editView, validate };
};

// セットアップ
export default defineComponent({
  components: {
    SettingTemplate,
    RecordList,
    SettingAction,
    TextInput,
    TextareaInput,
    CheckboxInput,
    IconButton,
    DisplayModeLabel,
    HeaderButton,
  },
  setup(props, context) {
    const { info, repositories, initApp, handleError } = common();
    const { confirm, confirmCancelEdit } = globalConfirm();
    const { state, init, add, get, save, del, cancel } = store(repositories);
    const { form, viewState, isEditing, initView, addView, editView, validate } = view();
    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await initApp();
        await init();
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
    const onAdd = () => {
      add();
      initView();
      addView();
    };
    const onEdit = () => {
      editView();
    };
    const onSave = async () => {
      if (validate()) {
        try {
          info.dispSmallProgress();
          await save();
          info.dispSuccess("更新しました。");
          initView();
        } catch (e) {
          handleError(e, context.root.$router, "データ更新に失敗しました。");
        }
        info.closeProgress();
      }
    };
    const onCancel = async () => {
      cancel();
      initView();
    };
    const onChange = async (value: string) => {
      const orgId = state.currentId;
      state.currentId = value;
      if (!(await confirmCancelEdit(isEditing.value))) {
        state.currentId = orgId;
        return;
      }
      try {
        info.dispSmallProgress();
        await get();
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    };
    const onDelete = async () => {
      if (!(await confirm("お知らせを削除してよろしいですか?"))) {
        return false;
      }
      try {
        info.dispSmallProgress();
        await del();
        info.dispSuccess("削除しました。");
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ削除に失敗しました。");
      }
      info.closeProgress();
    };
    return {
      onAdd,
      onEdit,
      onSave,
      onCancel,
      onChange,
      onDelete,
      state,
      viewState,
      isEditing,
      form,
      rules,
    };
  },
});
