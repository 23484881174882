export const themes = {
  light: {
    primary: "#333333",
    primaryAccent: "#0c7edb",
    primaryBg: "#EEEEEE",
    secondaryBg: "#e6f1f3",
    displayModeReadOnly: "#d1edff",
    displayModeEditing: "#dffcbf",
  },
};
