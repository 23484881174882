
























































































































































































































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";
import { globalConfirm } from "@/composable/utils/globalConfirm";
import SettingTemplate from "@/components/templates/SettingTemplate.vue";
import SettingAction from "@/components/molecules/SettingAction.vue";
import SearchTorikomiDataFormatSidebar from "@/components/organisms/SearchTorikomiDataFormatSidebar.vue";
import TextInput from "@/components/atoms/TextInput.vue";
import TextareaInput from "@/components/atoms/TextareaInput.vue";
import SelectInput from "@/components/atoms/SelectInput.vue";
import AutoCompleteInput from "@/components/atoms/AutoCompleteInput.vue";
import IconButton from "@/components/atoms/IconButton.vue";
import DisplayModeLabel from "@/components/molecules/DisplayModeLabel.vue";
import HeaderButton from "@/components/atoms/HeaderButton.vue";
import { TorikomiDataFormat, TorikomiDataFormatItem } from "@/models";
import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import { IRepositories } from "@/repositories/RepositoryFactory";
import vuexStore from "@/store";
import clone from "clone";
import type { PickPartial, KeyValue } from "@/types";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    torikomiDataFormats: Partial<TorikomiDataFormat>[];
    companies: KeyValue<string>[];
    currentId: string;
    sourceData: PickPartial<TorikomiDataFormat, "torikomiDataFormatItems">;
    formData: PickPartial<TorikomiDataFormat, "torikomiDataFormatItems">;
  }>({
    torikomiDataFormats: [],
    companies: [],
    currentId: "",
    sourceData: { torikomiDataFormatItems: [] },
    formData: { torikomiDataFormatItems: [] },
  });

  const init = async () => {
    await Promise.all([await initTorikomiDataFormats(), await getCompanies()]);
  };
  const initTorikomiDataFormats = async () => {
    await getAll();
    if (state.torikomiDataFormats.length > 0) {
      await get(state.torikomiDataFormats[0].id);
    } else {
      add();
    }
  };
  const getAll = async () => {
    state.torikomiDataFormats = await repositories.torikomiDataFormatRepository.getAll();
  };
  const getCompanies = async () => {
    state.companies = await repositories.companyRepository.getAll();
  };
  const get = async (id?: string) => {
    const currentTorikomiDataFormat = await repositories.torikomiDataFormatRepository.get(id ?? state.currentId);
    state.currentId = currentTorikomiDataFormat.id;
    state.sourceData = currentTorikomiDataFormat;
    state.formData = clone(currentTorikomiDataFormat);
  };
  const add = () => {
    state.sourceData = repositories.torikomiDataFormatRepository.template();
    state.formData = clone(state.sourceData);
    state.currentId = "";
  };
  const save = async () => {
    if (state.currentId === "") {
      state.currentId = await repositories.torikomiDataFormatRepository.register({
        ...state.formData,
        torikomiDataFormatItems: state.formData.torikomiDataFormatItems.filter((x) => x != null),
      });
    } else {
      await repositories.torikomiDataFormatRepository.update(state.currentId, {
        ...state.formData,
        torikomiDataFormatItems: state.formData.torikomiDataFormatItems.filter((x) => x != null),
      } as TorikomiDataFormat);
    }
    await getAll();
    await get();
  };
  const del = async () => {
    await repositories.torikomiDataFormatRepository.delete(state.currentId);
    state.currentId = "";
    await init();
  };
  const cancel = async () => {
    if (state.currentId === "") {
      if (state.torikomiDataFormats.length > 0) {
        await get(state.torikomiDataFormats[0].id);
      } else {
        state.formData = clone(state.sourceData);
      }
    } else {
      await get();
    }
  };

  return {
    state,
    init,
    getAll,
    add,
    get,
    save,
    del,
    cancel,
  };
};

const view = () => {
  const form = ref<VForm>();
  const viewState = reactive<{
    editMode: EditMode;
    validationMessage: string;
  }>({
    editMode: EditMode.Read,
    validationMessage: "",
  });
  const initView = () => {
    viewState.editMode = EditMode.Read;
    viewState.validationMessage = "";
    form.value?.resetValidation();
  };
  const addView = () => {
    viewState.editMode = EditMode.Add;
  };
  const editView = () => {
    viewState.editMode = EditMode.Edit;
  };
  const validate = () => {
    if (form.value?.validate()) {
      return true;
    }
    viewState.validationMessage = "入力内容を確認してください。";
    return false;
  };
  const isEditing = computed(() => viewState.editMode === EditMode.Add || viewState.editMode === EditMode.Edit);
  return { form, viewState, isEditing, initView, addView, editView, validate };
};

// セットアップ
export default defineComponent({
  components: {
    SettingTemplate,
    SettingAction,
    SearchTorikomiDataFormatSidebar,
    TextInput,
    TextareaInput,
    SelectInput,
    AutoCompleteInput,
    IconButton,
    DisplayModeLabel,
    HeaderButton,
  },
  setup(props, context) {
    const { info, repositories, initApp, handleError } = common();
    const { confirm, confirmCancelEdit } = globalConfirm();
    const { state, init, add, get, save, del, cancel } = store(repositories);
    const { form, viewState, isEditing, initView, addView, editView, validate } = view();
    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await initApp();
        await init();
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });
    const onAdd = () => {
      add();
      initView();
      addView();
    };
    const onEdit = () => {
      editView();
    };
    const onSave = async () => {
      if (validate()) {
        try {
          info.dispSmallProgress();
          await save();
          info.dispSuccess("更新しました。");
          initView();
        } catch (e) {
          handleError(e, context.root.$router, "データ更新に失敗しました。");
        }
        info.closeProgress();
      }
    };
    const onCancel = async () => {
      cancel();
      initView();
    };
    const onChange = async (value: string) => {
      const orgId = state.currentId;
      state.currentId = value;
      if (!(await confirmCancelEdit(isEditing.value))) {
        state.currentId = orgId;
        return;
      }
      try {
        info.dispSmallProgress();
        await get();
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    };
    const onDelete = async () => {
      if (!(await confirm("取込データフォーマットを削除してよろしいですか?"))) {
        return false;
      }
      try {
        info.dispSmallProgress();
        await del();
        info.dispSuccess("削除しました。");
        initView();
      } catch (e) {
        handleError(e, context.root.$router, "データ削除に失敗しました。");
      }
      info.closeProgress();
    };

    const addTorikomiDataFormatItem = () => {
      state.formData.torikomiDataFormatItems.push({
        colNo: null,
        colName: "",
        torikomiDataItemTimeFormatTypeId: null,
        torikomiDataItemTargetTypeId: null,
        note: "",
      });
    };

    const deleteTorikomiDataFormatItem = (index: number) => {
      context.root.$set(state.formData.torikomiDataFormatItems, index, null);
    };

    const torikomiDataFormatItems = computed(() => {
      return state.formData.torikomiDataFormatItems
        .map((x, index) => {
          return {
            formData: x,
            sourceData: state.sourceData.torikomiDataFormatItems[index] ?? {
              colNo: null,
              colName: "",
              torikomiDataItemTimeFormatTypeId: null,
              torikomiDataItemTargetTypeId: null,
              note: "",
            },
            index,
          };
        })
        .filter((x) => x.formData != null) as {
        formData: TorikomiDataFormatItem;
        sourceData: TorikomiDataFormatItem;
        index: number;
      }[];
    });
    const torikomiDataTypes = computed(() => {
      return vuexStore.getters.codeMasters.torikomiDataTypes;
    });
    const torikomiDataFormatTypes = computed(() => {
      return vuexStore.getters.codeMasters.torikomiDataFormatTypes;
    });
    const torikomiDataItemTimeFormatTypes = computed(() => {
      return vuexStore.getters.codeMasters.torikomiDataItemTimeFormatTypes;
    });
    const torikomiDataItemTargetTypes = computed(() => {
      return vuexStore.getters.codeMasters.torikomiDataItemTargetTypes;
    });
    return {
      onAdd,
      onEdit,
      onSave,
      onCancel,
      onChange,
      onDelete,
      state,
      viewState,
      isEditing,
      form,
      rules,
      addTorikomiDataFormatItem,
      deleteTorikomiDataFormatItem,
      torikomiDataFormatItems,
      torikomiDataTypes,
      torikomiDataFormatTypes,
      torikomiDataItemTimeFormatTypes,
      torikomiDataItemTargetTypes,
    };
  },
});
