import CryptoJS from "crypto-js";

const KEY = "sQcqobawgAwNlDvdmgI21wufVteVwGSbe1piAafn";

export const crypto = {
  /**
   * AES暗号化
   */
  encrypt: (data: string): string => {
    if (data) {
      return CryptoJS.AES.encrypt(data, KEY).toString();
    }
    return "";
  },
  /**
   * AES複合化
   */
  decrypt: (data: string): string => {
    if (data) {
      return CryptoJS.AES.decrypt(data, KEY).toString(CryptoJS.enc.Utf8);
    }
    return "";
  },
};
