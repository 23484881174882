import dayjs from "dayjs";

export const rules = {
  required: (value: string): string | boolean => (value != null && value !== "") || "必須項目です",

  positiveInteger: (value: string): string | boolean =>
    !value || (/^[0-9]+$/.test(value) && parseInt(value) > 0) || "1以上の整数を入力してください",

  zipCode: (value: string): string | boolean => !value || /^\d{3}-?\d{4}$/.test(value) || "書式を確認してください",

  tel: (value: string): string | boolean =>
    // 連続しないハイフンを除いて0から始まる数値10-11桁
    !value || (!value.match(/-{2,}/g) && /^0\d{9,10}$/.test(value.replace(/-/g, ""))) || "書式を確認してください",

  email: (value: string): string | boolean =>
    !value ||
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(value) ||
    "書式を確認してください",
  max: (maxNum: number) => {
    return (value: string): string | boolean => !value || value.length <= maxNum || `最大文字数は${maxNum}文字です`;
  },
  min: (minNum: number) => {
    return (value: string): string | boolean => !value || value.length >= minNum || `最小文字数は${minNum}文字です`;
  },
  code: (value: string): string | boolean =>
    !value || /^[a-zA-Z0-9-_]+$/.test(value) || "使用できない文字が含まれています",
  password: (value: string): string | boolean =>
    // eslint-disable-next-line no-useless-escape
    !value || /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]+$/.test(value) || "使用できない文字が含まれています",

  beforeDate: (targetValue: string | null | undefined, targetName: string, sameOrBefore = true) => {
    return (value: string): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      const currentDate = dayjs(value);
      const targetDate = dayjs(targetValue);
      if (
        !currentDate.isValid() ||
        !targetDate.isValid() ||
        currentDate.isBefore(targetDate) ||
        (sameOrBefore && currentDate.isSame(targetDate))
      ) {
        return true;
      }
      return `${targetName}より後の日付です`;
    };
  },
  afterDate: (targetValue: string | null | undefined, targetName: string, sameOrAfter = true) => {
    return (value: string): string | boolean => {
      if (!value || !targetValue) {
        return true;
      }
      const currentDate = dayjs(value);
      const targetDate = dayjs(targetValue);
      if (
        !currentDate.isValid() ||
        !targetDate.isValid() ||
        currentDate.isAfter(targetDate) ||
        (sameOrAfter && currentDate.isSame(targetDate))
      ) {
        return true;
      }
      return `${targetName}より前の日付です`;
    };
  },
  dateString: (value: string): string | boolean =>
    !value || /^(1|2)\d{3}[/-]?(0?[1-9]|1[0-2])[/-]?(0?[1-9]|[12][0-9]|3[01])$/.test(value) || "書式を確認してください",
};
