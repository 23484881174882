import type { TorikomiDataFormat } from "@/models";
import type { OmitPartial } from "@/types";
import { ITorikomiDataFormatRepository } from "@/repositories";

export class MockTorikomiDataFormatRepository implements ITorikomiDataFormatRepository {
  private mockData: TorikomiDataFormat[] = [
    {
      id: "id-1",
      name: "フォーマット1",
      torikomiDataTypeId: 1,
      torikomiDataFormatTypeId: 1,
      companyId: "id-1",
      note: "ノート",
      torikomiDataFormatItems: [
        {
          colNo: 1,
          colName: "項目名",
          torikomiDataItemTimeFormatTypeId: 1,
          torikomiDataItemTargetTypeId: 1,
          note: "備考1",
        },
        {
          colNo: 2,
          colName: "項目名2",
          torikomiDataItemTimeFormatTypeId: 2,
          torikomiDataItemTargetTypeId: 2,
          note: "備考2",
        },
        {
          colNo: 3,
          colName: "項目名3",
          torikomiDataItemTimeFormatTypeId: 1,
          torikomiDataItemTargetTypeId: 3,
          note: "備考3",
        },
      ],
    },
    {
      id: "id-2",
      name: "フォーマット2",
      torikomiDataTypeId: 2,
      torikomiDataFormatTypeId: 2,
      companyId: "id-1",
      note: "ノート",
      torikomiDataFormatItems: [
        {
          colNo: 1,
          colName: "項目名",
          torikomiDataItemTimeFormatTypeId: 1,
          torikomiDataItemTargetTypeId: 1,
          note: "備考1",
        },
      ],
    },
  ];
  private getLastMockId() {
    return Math.max(...this.mockData.map((x) => Number(x.id.split("-")[1])));
  }
  public async getAll(): Promise<TorikomiDataFormat[]> {
    return this.mockData;
  }
  public template(): OmitPartial<TorikomiDataFormat, "id"> {
    return {
      id: "",
      name: "",
      torikomiDataTypeId: null,
      torikomiDataFormatTypeId: null,
      companyId: null,
      note: null,
      torikomiDataFormatItems: [],
    };
  }
  public async get(id: string): Promise<TorikomiDataFormat> {
    const data = this.mockData.find((x) => x.id === id);
    if (data == null) {
      throw new Error();
    }
    return data;
  }
  public async register(data: Partial<TorikomiDataFormat>): Promise<string> {
    data.id = `id-${this.getLastMockId() + 1}`;
    this.mockData.push(data as TorikomiDataFormat);
    return data.id;
  }
  public async update(id: string, data: TorikomiDataFormat): Promise<void> {
    const target = this.mockData.find((x) => x.id === id);
    if (target == null) {
      throw new Error();
    }
    target.name = data.name;
    target.torikomiDataTypeId = data.torikomiDataTypeId;
    target.torikomiDataFormatTypeId = data.torikomiDataFormatTypeId;
    target.companyId = data.companyId;
    target.note = data.note;
    target.torikomiDataFormatItems = data.torikomiDataFormatItems;
  }
  public async delete(id: string): Promise<void> {
    const index = this.mockData.findIndex((x) => x.id === id);
    if (index === -1) {
      throw new Error();
    }
    this.mockData.splice(index, 1);
  }
}
