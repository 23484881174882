import type { Oshirase } from "@/models";
import type { KeyValue, OmitPartial } from "@/types";
import { IOshiraseRepository } from "@/repositories";

export class MockOshiraseRepository implements IOshiraseRepository {
  private mockData: Oshirase[] = [
    {
      id: "id-1",
      title: "タイトル1",
      message: "メッセージ1",
      isActive: true,
    },
    {
      id: "id-2",
      title: "タイトル2",
      message: "メッセージ2",
      isActive: true,
    },
    {
      id: "id-3",
      title: "タイトル3",
      message: "メッセージ3",
      isActive: false,
    },
  ];
  private getLastMockId() {
    return Math.max(...this.mockData.map((x) => Number(x.id.split("-")[1])));
  }
  public async getAll(): Promise<KeyValue<string>[]> {
    return this.mockData.map((x) => {
      return { id: x.id, name: x.title };
    });
  }
  public template(): OmitPartial<Oshirase, "id"> {
    return {
      id: "",
      title: "",
      message: "",
      isActive: true,
    };
  }
  public async get(id: string): Promise<Oshirase> {
    const data = this.mockData.find((x) => x.id === id);
    if (data == null) {
      throw new Error();
    }
    return data;
  }
  public async register(data: Partial<Oshirase>): Promise<string> {
    data.id = `id-${this.getLastMockId() + 1}`;
    this.mockData.push(data as Oshirase);
    return data.id;
  }
  public async update(id: string, data: Oshirase): Promise<void> {
    const target = this.mockData.find((x) => x.id === id);
    if (target == null) {
      throw new Error();
    }
    target.title = data.title;
    target.message = data.message;
    target.isActive = data.isActive;
  }
  public async delete(id: string): Promise<void> {
    const index = this.mockData.findIndex((x) => x.id === id);
    if (index === -1) {
      throw new Error();
    }
    this.mockData.splice(index, 1);
  }
}
