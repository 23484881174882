import * as Repositories from "@/repositories";
import * as MockRepositories from "./mock";

// provide用Key
export const RepositoriesKey = Symbol("repositories");

export interface IRepositories {
  authRepository: Repositories.IAuthRepository;
  codeMastersRepository: Repositories.ICodeMastersRepository;
  companyRepository: Repositories.ICompanyRepository;
  shukujitsuRepository: Repositories.IShukujitsuRepository;
  saiteiChinginRepository: Repositories.ISaiteiChinginRepository;
  oshiraseRepository: Repositories.IOshiraseRepository;
  torikomiDataFormatRepository: Repositories.ITorikomiDataFormatRepository;
  liteApplyRepository: Repositories.ILiteApplyRepository;
  kyuyoTaikeiRepository: Repositories.IKyuyoTaikeiRepository;
}
export const repositories: IRepositories = {
  authRepository: new Repositories.AuthRepository(),
  codeMastersRepository: new Repositories.CodeMastersRepository(),
  companyRepository: new Repositories.CompanyRepository(),
  shukujitsuRepository: new Repositories.ShukujitsuRepository(),
  saiteiChinginRepository: new Repositories.SaiteiChinginRepository(),
  oshiraseRepository: new Repositories.OshiraseRepository(),
  torikomiDataFormatRepository: new Repositories.TorikomiDataFormatRepository(),
  liteApplyRepository: new Repositories.LiteApplyRepository(),
  kyuyoTaikeiRepository: new Repositories.KyuyoTaikeiRepository(),
};

export const mockRepositories: IRepositories = {
  authRepository: new MockRepositories.MockAuthRepository(),
  codeMastersRepository: new MockRepositories.MockCodeMastersRepository(),
  companyRepository: new MockRepositories.MockCompanyRepository(),
  shukujitsuRepository: new MockRepositories.MockShukujitsuRepository(),
  saiteiChinginRepository: new MockRepositories.MockSaiteiChinginRepository(),
  oshiraseRepository: new MockRepositories.MockOshiraseRepository(),
  torikomiDataFormatRepository: new MockRepositories.MockTorikomiDataFormatRepository(),
  liteApplyRepository: new MockRepositories.MockLiteApplyRepository(),
  kyuyoTaikeiRepository: new MockRepositories.MockKyuyoTaikeiRepository(),
};
