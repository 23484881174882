import type { Company } from "@/models";
import type { PickPartial, OmitPartial } from "@/types";
import { ICompanyRepository } from "@/repositories";
import { CompanyKeiyakuStatus } from "@/composable/utils/const";

export class MockCompanyRepository implements ICompanyRepository {
  private mockData: Company[] = [
    {
      id: "id-1",
      companyCode: "1111",
      riyoKaishiDate: "2020/1/1",
      riyoShuryoDate: "2021/1/1",
      jigyoTypeId: 1,
      name: "会社1",
      nameShort: "略称1",
      postalCode: "123-4567",
      address1: "住所1",
      address2: "住所1b",
      tel: "03-1111-1111",
      fax: "03-2222-2222",
      tantosha: "担当 太郎",
      sekininsha: "責任 二郎",
      email: "aaa@bbb.com ",
      hasSeikyuSaki: true,
      seikyuSakiAtesaki: "請求先宛先1",
      seikyuSakiTantosha: "請求先担当者1",
      seikyuSakiPostalCode: "123-1111",
      seikyuSakiAddress1: "請求先住所1",
      seikyuSakiAddress2: "請求先住所1b",
      seikyuSakiTel: "03-3333-3333",
      isUseKyuyoFeature: true,
      companyKeiyakuStatus: CompanyKeiyakuStatus.通常版利用中,
    },
    {
      id: "id-2",
      companyCode: "2222",
      riyoKaishiDate: "2020/1/1",
      riyoShuryoDate: null,
      jigyoTypeId: 1,
      name: "会社2",
      nameShort: "略称2",
      postalCode: "123-4567",
      address1: "住所2",
      address2: "住所2b",
      tel: "03-1111-1111",
      fax: "03-2222-2222",
      tantosha: "担当 太郎",
      sekininsha: "責任 二郎",
      email: "aaa@bbb.com ",
      hasSeikyuSaki: true,
      seikyuSakiAtesaki: "請求先宛先2",
      seikyuSakiTantosha: "請求先担当者2",
      seikyuSakiPostalCode: "123-1111",
      seikyuSakiAddress1: "請求先住所2",
      seikyuSakiAddress2: "請求先住所2b",
      seikyuSakiTel: "03-3333-3333",
      isUseKyuyoFeature: false,
      companyKeiyakuStatus: CompanyKeiyakuStatus.通常版利用中,
    },
  ];
  private getLastMockId() {
    return Math.max(...this.mockData.map((x) => Number(x.id.split("-")[1])));
  }
  public async getAll(): Promise<PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">[]> {
    return this.mockData.map((x) => {
      return {
        id: x.id,
        name: x.name,
        companyCode: x.companyCode,
        nameShort: x.nameShort,
        companyKeiyakuStatus: x.companyKeiyakuStatus,
      };
    });
  }
  public template(): OmitPartial<Company, "id"> {
    return {
      companyCode: "",
      riyoKaishiDate: "",
      riyoShuryoDate: null,
      jigyoTypeId: null,
      name: "",
      nameShort: null,
      postalCode: null,
      address1: null,
      address2: null,
      tel: null,
      fax: null,
      tantosha: null,
      sekininsha: null,
      email: null,
      hasSeikyuSaki: false,
      seikyuSakiAtesaki: null,
      seikyuSakiTantosha: null,
      seikyuSakiPostalCode: null,
      seikyuSakiAddress1: null,
      seikyuSakiAddress2: null,
      seikyuSakiTel: null,
      isUseKyuyoFeature: false,
    };
  }
  public async get(id: string): Promise<Readonly<Company>> {
    const company = this.mockData.find((x) => x.id === id);
    if (company == null) {
      throw new Error();
    }
    return company;
  }
  public async getGeneratedCompanyCode(): Promise<string> {
    return Math.random().toString(32).substring(2);
  }
  public async checkCompanyCode(companyCode: string): Promise<boolean> {
    return this.mockData.some((x) => x.companyCode === companyCode);
  }
  public async getEepsilonRegistrationDate(): Promise<string | null> {
    return "2022-02-01";
  }
  public async register(data: Partial<Company>): Promise<string> {
    data.id = `id-${this.getLastMockId() + 1}`;
    this.mockData.push(data as Company);
    return data.id;
  }
  public async update(id: string, data: Company): Promise<void> {
    const target = this.mockData.find((x) => x.id === id);
    if (target == null) {
      throw new Error();
    }
    target.companyCode = data.companyCode;
    target.riyoKaishiDate = data.riyoKaishiDate;
    target.riyoShuryoDate = data.riyoShuryoDate;
    target.jigyoTypeId = data.jigyoTypeId;
    target.name = data.name;
    target.nameShort = data.nameShort;
    target.postalCode = data.postalCode;
    target.address1 = data.address1;
    target.address2 = data.address2;
    target.tel = data.tel;
    target.fax = data.fax;
    target.tantosha = data.tantosha;
    target.sekininsha = data.sekininsha;
    target.email = data.email;
    target.hasSeikyuSaki = data.hasSeikyuSaki;
    target.seikyuSakiAtesaki = data.seikyuSakiAtesaki;
    target.seikyuSakiTantosha = data.seikyuSakiTantosha;
    target.seikyuSakiPostalCode = data.seikyuSakiPostalCode;
    target.seikyuSakiAddress1 = data.seikyuSakiAddress1;
    target.seikyuSakiAddress2 = data.seikyuSakiAddress2;
    target.seikyuSakiTel = data.seikyuSakiTel;
    target.isUseKyuyoFeature = data.isUseKyuyoFeature;
  }
  public async delete(id: string): Promise<void> {
    const index = this.mockData.findIndex((x) => x.id === id);
    if (index === -1) {
      throw new Error();
    }
    this.mockData.splice(index, 1);
  }
}
