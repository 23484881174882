












import { defineComponent, PropType, computed } from "@vue/composition-api";
import type { KeyValue } from "@/types/keyValue";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<KeyValue<string | number>[]>,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props, context) {
    const innerValue = computed({
      get: () => props.value,
      set: (value: string | number) => {
        if (value != null) {
          context.emit("input", value);
          context.emit("change");
        }
      },
    });
    return { innerValue };
  },
});
