






























































import { defineComponent, reactive, onMounted, ref, computed } from "@vue/composition-api";
import { common } from "@/composable/common";

import SearchTorikomiDataFormatSidebar from "@/components/organisms/SearchTorikomiDataFormatSidebar.vue";
import TextInput from "@/components/atoms/TextInput.vue";
import TextareaInput from "@/components/atoms/TextareaInput.vue";
import SelectInput from "@/components/atoms/SelectInput.vue";
import IconButton from "@/components/atoms/IconButton.vue";
import DisplayModeLabel from "@/components/molecules/DisplayModeLabel.vue";
import HeaderButton from "@/components/atoms/HeaderButton.vue";

import { VForm } from "@/types/vuetify/vForm";
import { rules } from "@/composable/utils/rules";
import { EditMode } from "@/composable/utils/const";
import { IRepositories } from "@/repositories/RepositoryFactory";
import type { PickPartial } from "@/types";
import DialogButton from "@/components/molecules/DialogButton.vue";
import KyuyoTaikeiImportDialog from "@/components/organisms/KyuyoTaikeiImportDialog.vue";
import KyuyoTaikeiExportDialog from "@/components/organisms/KyuyoTaikeiExportDialog.vue";
import AdminTemplate from "@/components/templates/AdminTemplate.vue";
import dayjs from "dayjs";
import { Company } from "@/models";
import { KyuyoTaikei, KyuyoItem } from "@/models";

const store = (repositories: IRepositories) => {
  const state = reactive<{
    companies: PickPartial<Company, "id" | "name" | "companyKeiyakuStatus">[];
    kyuyoTaikeis: Partial<KyuyoTaikei>[];
    kyuyoItems: KyuyoItem[];
    importDialog: boolean;
    exportDialog: boolean;
  }>({
    companies: [],
    kyuyoTaikeis: [],
    kyuyoItems: [],
    importDialog: false,
    exportDialog: false,
  });

  const init = async () => {
    state.importDialog = false;
    state.exportDialog = false;
    state.companies = [];
    await getAllCompanyList();
  };

  const getAllCompanyList = async () => {
    state.companies = await repositories.companyRepository.getAll();
  };

  const importTaikei = async (importData: {
    uploadData: File | null;
    prefix: string;
    suffix: string;
    uploadFileName: string;
    companyId: string;
  }) => {
    if (importData.uploadData)
      await repositories.kyuyoTaikeiRepository.import(
        importData.companyId,
        importData.uploadData,
        importData.prefix,
        importData.suffix
      );
  };

  return {
    state,
    init,
    importTaikei,
  };
};

const view = () => {
  const form = ref<VForm>();
  const viewState = reactive<{
    editMode: EditMode;
    validationMessage: string;
  }>({
    editMode: EditMode.Read,
    validationMessage: "",
  });
  const initView = () => {
    viewState.editMode = EditMode.Read;
    viewState.validationMessage = "";
    form.value?.resetValidation();
  };

  const isEditing = computed(() => viewState.editMode === EditMode.Add || viewState.editMode === EditMode.Edit);
  return { form, viewState, isEditing, initView };
};

// セットアップ
export default defineComponent({
  components: {
    SearchTorikomiDataFormatSidebar,
    TextInput,
    TextareaInput,
    SelectInput,
    IconButton,
    DisplayModeLabel,
    HeaderButton,
    DialogButton,
    KyuyoTaikeiImportDialog,
    KyuyoTaikeiExportDialog,
    AdminTemplate,
  },
  setup(props, context) {
    const { info, repositories, initApp, handleError, downloadFile } = common();
    const { state, init, importTaikei } = store(repositories);
    const { form, viewState, isEditing, initView } = view();

    onMounted(async () => {
      info.dispSmallProgress();
      try {
        await init();
        await initApp();
        initView();
      } catch (e) {
        handleError(e as Error, context.root.$router, "データ取得に失敗しました。");
      }
      info.closeProgress();
    });

    const onImport = async (importData: {
      uploadData: File | null;
      prefix: string;
      suffix: string;
      uploadFileName: string;
      companyId: string;
    }) => {
      state.importDialog = false;
      info.dispSmallProgress();
      try {
        await importTaikei(importData);
        info.dispSuccess("給与体系と給与項目の取込が完了しました。");
      } catch (ex) {
        handleError(ex as Error, context.root.$router, "給与体系と給与項目の取込に失敗しました。");
      }
      info.closeProgress();
    };

    const onExport = async (companyId: string, kyuyoTaikeiIds: string[]) => {
      state.exportDialog = false;
      info.dispSmallProgress();
      try {
        var blob = await exportTaikei(companyId, kyuyoTaikeiIds);
        console.info("blob-data", blob);
        downloadFile(blob, `給与体系_給与項目_${dayjs().format("YYYYMMDDHHmmss")}.json`, "text/csv");
      } catch (e) {
        handleError(e as Error, context.root.$router, "給与体系と給与項目の出力に失敗しました。");
      }
      info.closeProgress();
    };

    const exportTaikei = async (companyId: string, kyuyoTaikeiIds: string[]) => {
      const res = await repositories.kyuyoTaikeiRepository.export(companyId, kyuyoTaikeiIds);
      console.log("res", res);
      if (res == null) {
        throw new Error("取込失敗");
      }
      return res;
    };

    return {
      onImport,
      onExport,
      state,
      viewState,
      isEditing,
      form,
      rules,
      exportTaikei,
    };
  },
});
