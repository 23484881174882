import type { Oshirase } from "@/models";
import type { OmitPartial, KeyValue } from "@/types";
import { OshirasesService, RequestOshiraseCreateParam, RequestOshiraseUpdateParam } from "@/openapi";

export interface IOshiraseRepository {
  getAll: () => Promise<KeyValue<string>[]>;
  template: () => OmitPartial<Oshirase, "id">;
  get: (id: string) => Promise<Oshirase>;
  register: (data: Partial<Oshirase>) => Promise<string>;
  update: (id: string, data: Oshirase) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export class OshiraseRepository implements IOshiraseRepository {
  public async getAll(): Promise<KeyValue<string>[]> {
    const res = await OshirasesService.getOshirasesService();
    return (res?.map((x) => ({
      id: x.id,
      name: x.title,
    })) ?? []) as KeyValue<string>[];
  }
  public template(): OmitPartial<Oshirase, "id"> {
    return {
      id: "",
      title: "",
      message: "",
      isActive: true,
    };
  }
  public async get(id: string): Promise<Oshirase> {
    return (await OshirasesService.getOshirasesService1({ id })) as Oshirase;
  }
  public async register(data: Partial<Oshirase>): Promise<string> {
    return (await OshirasesService.postOshirasesService({
      requestBody: data as RequestOshiraseCreateParam,
    })) as string;
  }
  public async update(id: string, data: Oshirase): Promise<void> {
    await OshirasesService.putOshirasesService({
      id,
      requestBody: data as RequestOshiraseUpdateParam,
    });
  }
  public async delete(id: string): Promise<void> {
    await OshirasesService.deleteOshirasesService({ id });
  }
}
